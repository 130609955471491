<template>
  <div
    :class="['leftbar', 'right0', { leftbar_open: leftbarOpen }]"
    @mouseover="leftbarOpen = true"
    @mouseout="leftbarOpen = false"
  >
    <div class="lefttop">
    </div>
    <div class="leftmenu">
      <router-link :to="item.route" exact class="menu_link" v-for="(item, i) in items" :key="i">
        <span class="menu_icon">
          <fa :icon="item.icon" />
        </span>
        <div class="menu_text">{{ item.label }}</div>
      </router-link>
    </div>
    <div class="leftbottom">
      <a href="mailto:support.mqpro@quadral.fr">Aide et support</a>
      <router-link :to="{ name: 'cgu' }">Mentions légales - CGU</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
  data() {
    return {
      leftbarOpen: false,
    };
  },
};
</script>
