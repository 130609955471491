export const FETCH_GET_CONTACT = 1;
export const FETCH_GET_COMMUNES = 16;
export const FETCH_PUT_CONTACT = 2;
export const FETCH_POST_CONTACT = 17;
export const FETCH_GET_INDICATORS = 3;
export const FETCH_POST_PROGRAMS = 4;
export const FETCH_GET_PROGRAM = 5;
export const FETCH_GET_PV_INTERLOS = 14;
export const FETCH_POST_BIS = 6;
export const FETCH_GET_BI = 7;
export const FETCH_GET_BI_LOCAPRINC = 15;
export const FETCH_GET_BI_ENTRETIENS = 8;
export const FETCH_GET_BI_DOSSIERS = 9;
export const FETCH_PUT_BI = 10;
export const FETCH_PUT_BI_LOCAPRINC = 11;
export const FETCH_PUT_BI_VACANT = 12;
export const FETCH_GET_SEARCH = 13;

export enum fetchMode {
    mock = 1,
    crm = 2,
    api = 3,
}

export const FETCH_MODE = {
    [FETCH_GET_CONTACT]         : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_COMMUNES]        : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_PUT_CONTACT]         : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_POST_CONTACT]        : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_INDICATORS]      : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_POST_PROGRAMS]       : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_PROGRAM]         : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_PV_INTERLOS]     : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_POST_BIS]            : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_BI]              : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_BI_LOCAPRINC]    : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_BI_ENTRETIENS]   : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_BI_DOSSIERS]     : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_PUT_BI]              : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_PUT_BI_LOCAPRINC]    : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_PUT_BI_VACANT]       : { development: fetchMode.api  , production: fetchMode.api     },
    [FETCH_GET_SEARCH]          : { development: fetchMode.api  , production: fetchMode.api     },
};

const ENV = process.env.NODE_ENV;
// const ENV = 'production';

export function isMock(routeId) {
    return FETCH_MODE[routeId][ENV] === fetchMode.mock;
}
export function isApi(routeId) {
    return FETCH_MODE[routeId][ENV] === fetchMode.api;
}
export function isCrm(routeId) {
    return FETCH_MODE[routeId][ENV] === fetchMode.crm;
}

interface requestMock {
    id: number,
    method?: string,
    pattern: string | RegExp,
    response: {
        data: any,
        status?: number,
        message?: string,
    }
}

export const MOCK_GET_CONTACT: requestMock = {
    id: FETCH_GET_CONTACT,
    method: 'get',
    pattern: '/contact',
    response: {
        data: {
            address1_line1: '4 Impasse des Marchais',
            address1_line2: '',
            address1_city: 'Collégien',
            qua_codepostal: 'd091a114-528f-e711-80f0-3863bb357dc8',
            qua_codepostal_label: '77090',
            emailaddress1: 'OAB_devs@QTbox.onmicrosoft.com',
            mobilephone: '0623456789',
            linked_accounts: [
                { accountid: 'bebca4ef-a98c-eb11-b1ac-000d3a2e7a8b', name: 'SEQENS', mso_roleoption: 861810000 },
                { accountid: '43', name: '3F Seine et Marne' },
            ],
        }
    },
}

export const MOCK_GET_COMMUNES: requestMock = {
    id: FETCH_GET_COMMUNES,
    method: 'get',
    pattern: /^\/communes\/search\//,
    response: {
        data: [
            { qua_communeid: '9f36da54-ade4-eb11-bacb-000d3a4af9b2', qua_name: '95610', qua_departement: '95', qua_ville: 'ERAGNY' },
            { qua_communeid: 'fd719fb9-dc36-ea11-a813-000d3a2cb36f', qua_name: '69130', qua_departement: '69', qua_ville: 'ECULLY' },
            { qua_communeid: 'f6c35d2f-4501-ea11-a811-000d3a252d60', qua_name: '97229', qua_departement: '97', qua_ville: 'L\'ANSE A L\'ANE' },
            { qua_communeid: '35e830e5-8590-eb11-b1ac-000d3a2e4012', qua_name: '41400', qua_departement: '41', qua_ville: 'BOURRE' },
            { qua_communeid: '8f65d099-e161-e911-a95c-000d3a454330', qua_name: '34671', qua_departement: '34', qua_ville: 'BAILLARGUES' },
            { qua_communeid: 'de7fc54d-6996-e911-a962-000d3a454977', qua_name: '59630', qua_departement: '59', qua_ville: 'BOURBOURG' },
            { qua_communeid: '4479417e-518f-e711-80f0-3863bb357dc8', qua_name: '01090', qua_departement: '01', qua_ville: 'GENOUILLEUX' },
        ]
    },
};

export const MOCK_PUT_CONTACT: requestMock = {
    id: FETCH_PUT_CONTACT,
    method: 'put',
    pattern: '/contact',
    response: {
        data: 'C\'est okeysh'
    },
};

export const MOCK_POST_CONTACT: requestMock = {
    id: FETCH_POST_CONTACT,
    method: 'post',
    pattern: '/contact',
    response: {
        data: 'C\'est okeysh'
    },
}

export const MOCK_GET_INDICATORS: requestMock = {
    id: FETCH_GET_INDICATORS,
    method: 'get',
    pattern: '/indicators',
    response: {
        data: [{
            accountid: '42',
            mso_caannee: 63000000,
            mso_caobjectif: 70000000,
            mso_capourcent: 34,
            mso_catendance: 30,
            mso_biannee: 990,
            mso_biobjectif: 1050,
            mso_bipourcent: 45,
            mso_bitendance: 60,
            mso_biprecoccupe: 4500,
            mso_biprecvacant: 1000,
            mso_bianneeoccupe: 520,
            mso_bianneevacant: 105,
            mso_cacomplement: 2500000,
            mso_bicomplement: 51,
            mso_dvanneeencours: 32,
            mso_dvprecencours: 143,
            mso_bi: 6142,
            mso_dvanneeannules: 142,
        }, {
            accountid: '43',
            mso_caannee: 70000,
            mso_caobjectif: 70000,
            mso_capourcent: 100,
            mso_catendance: 30,
            mso_biannee: 450,
            mso_biobjectif: 1050,
            mso_bipourcent: 45,
            mso_bitendance: 60,
            mso_biprecoccupe: 4500,
            mso_biprecvacant: 1000,
            mso_bianneeoccupe: 520,
            mso_bianneevacant: 105,
            mso_cacomplement: 250000,
            mso_bicomplement: 51,
            mso_dvanneeencours: 32,
            mso_dvprecencours: 143,
            mso_bi: 6000,
        }]
    }
};

export const MOCK_GET_PV_INTERLOS: requestMock = {
    id: FETCH_GET_PV_INTERLOS,
    method: 'get',
    pattern: /^\/program\/[0-9A-Fa-f-]+\/interlos/,
    response: {
        data: {
            qua_programmedeventeid: "aa0f297d-31bd-eb11-bacc-000d3a441033",
            qua_name: "BOURG EN BRESSE - BG A1 - ESI001 - SEQENS",
            "qua_gestionnaireadvid.systemuserid": null,
            "qua_gestionnaireadvid.fullname": null,
            "qua_gestionnaireadvid.mobilephone": null,
            "qua_gestionnaireadvid.internalemailaddress": null,
            "qua_commercialreferentid.systemuserid": "2cbebd34-8781-e611-80ea-c4346bac0ff4",
            "qua_commercialreferentid.fullname": "Développeurs OAB",
            "qua_commercialreferentid.mobilephone": "+33 6 30 49 83 14",
            "qua_commercialreferentid.internalemailaddress": "OAB_devs@QTbox.onmicrosoft.com",
            "qua_chargemarketingid.systemuserid": null,
            "qua_chargemarketingid.fullname": null,
            "qua_chargemarketingid.mobilephone": null,
            "qua_chargemarketingid.internalemailaddress": null,
            "qua_chargefinancementid.systemuserid": null,
            "qua_chargefinancementid.fullname": null,
            "qua_chargefinancementid.mobilephone": null,
            "qua_chargefinancementid.internalemailaddress": null,
            "qua_chargeadvid.systemuserid": null,
            "qua_chargeadvid.fullname": null,
            "qua_chargeadvid.mobilephone": null,
            "qua_chargeadvid.internalemailaddress": null,
            "qua_notairemandantid.accountid": null,
            "qua_notairemandantid.name": null,
            "qua_notairemandantid.telephone1": null,
            "qua_notairemandantid.emailaddress1": null
        }
    }
}

export const MOCK_POST_BIS: requestMock = {
    id: FETCH_POST_BIS,
    method: 'post',
    pattern: '/bienimmos',
    response: {
        data: {
            data: [
                {
                    "@odata.etag": "W/\"145829760\"",
                    "qua_etat@OData.Community.Display.V1.FormattedValue": "Vente en cours",
                    "qua_etat": 975250001,
                    "qua_bienimmobilierid": "69b9946a-864f-ec11-8c62-002248811d98",
                    "qua_destination@OData.Community.Display.V1.FormattedValue": "Divers",
                    "qua_destination": 975250004,
                    "qua_occupevacant@OData.Community.Display.V1.FormattedValue": "Vacant",
                    "qua_occupevacant": false,
                    "qua_name": "371000",
                    "qua_etage@OData.Community.Display.V1.FormattedValue": "1",
                    "qua_etage": 975250002,
                    "qua_surfacehabitable@OData.Community.Display.V1.FormattedValue": "0,00",
                    "qua_surfacehabitable": 0.0,
                    "qua_nature@OData.Community.Display.V1.FormattedValue": "Cave",
                    "qua_nature": 975250010,
                    "qua_ensembleimmobilier.qua_adresse1@OData.Community.Display.V1.AttributeName": "qua_adresse1",
                    "qua_ensembleimmobilier.qua_adresse1": "SQU LEON VOGEL",
                    "qua_ensembleimmobilier.qua_commune@OData.Community.Display.V1.AttributeName": "qua_commune",
                    "qua_ensembleimmobilier.qua_commune@OData.Community.Display.V1.FormattedValue": "78260",
                    "qua_ensembleimmobilier.qua_commune@Microsoft.Dynamics.CRM.lookuplogicalname": "qua_commune",
                    "qua_ensembleimmobilier.qua_commune": "4796a114-528f-e711-80f0-3863bb357dc8",
                    "qua_ensembleimmobilier.qua_programmedevente@OData.Community.Display.V1.AttributeName": "qua_programmedevente",
                    "qua_ensembleimmobilier.qua_programmedevente@OData.Community.Display.V1.FormattedValue": "ACHERES - ACHERES - V90 2493 - SEQENS",
                    "qua_ensembleimmobilier.qua_programmedevente@Microsoft.Dynamics.CRM.lookuplogicalname": "qua_programmedevente",
                    "qua_ensembleimmobilier.qua_programmedevente": "f5226e23-f44d-ec11-8c62-002248811d98",
                    "qua_ensembleimmobilier.statecode@OData.Community.Display.V1.AttributeName": "statecode",
                    "qua_ensembleimmobilier.statecode@OData.Community.Display.V1.FormattedValue": "Actif",
                    "qua_ensembleimmobilier.statecode": 0,
                    "qua_ensembleimmobilier.qua_ville@OData.Community.Display.V1.AttributeName": "qua_ville",
                    "qua_ensembleimmobilier.qua_ville": "ACHERES",
                    "qua_ensembleimmobilier.qua_mandant@OData.Community.Display.V1.AttributeName": "qua_mandant",
                    "qua_ensembleimmobilier.qua_mandant@OData.Community.Display.V1.FormattedValue": "SEQENS",
                    "qua_ensembleimmobilier.qua_mandant@Microsoft.Dynamics.CRM.lookuplogicalname": "account",
                    "qua_ensembleimmobilier.qua_mandant": "bebca4ef-a98c-eb11-b1ac-000d3a2e7a8b"
                },
                {
                    "@odata.etag": "W/\"145733893\"",
                    "qua_etat@OData.Community.Display.V1.FormattedValue": "Cessible",
                    "qua_etat": 975250000,
                    "qua_bienimmobilierid": "12339176-864f-ec11-8c62-002248811d98",
                    "qua_destination@OData.Community.Display.V1.FormattedValue": "Habitation",
                    "qua_destination": 975250000,
                    "qua_occupevacant@OData.Community.Display.V1.FormattedValue": "Occupé",
                    "qua_occupevacant": true,
                    "qua_name": "371001",
                    "qua_etage@OData.Community.Display.V1.FormattedValue": "1",
                    "qua_etage": 975250002,
                    "qua_surfacehabitable@OData.Community.Display.V1.FormattedValue": "0,00",
                    "qua_surfacehabitable": 0.0,
                    "qua_typologie@OData.Community.Display.V1.FormattedValue": "T1",
                    "qua_typologie": 975250000,
                    "qua_ensembleimmobilier.qua_adresse1@OData.Community.Display.V1.AttributeName": "qua_adresse1",
                    "qua_ensembleimmobilier.qua_adresse1": "SQU LEON VOGEL",
                    "qua_ensembleimmobilier.qua_commune@OData.Community.Display.V1.AttributeName": "qua_commune",
                    "qua_ensembleimmobilier.qua_commune@OData.Community.Display.V1.FormattedValue": "78260",
                    "qua_ensembleimmobilier.qua_commune@Microsoft.Dynamics.CRM.lookuplogicalname": "qua_commune",
                    "qua_ensembleimmobilier.qua_commune": "4796a114-528f-e711-80f0-3863bb357dc8",
                    "qua_ensembleimmobilier.qua_programmedevente@OData.Community.Display.V1.AttributeName": "qua_programmedevente",
                    "qua_ensembleimmobilier.qua_programmedevente@OData.Community.Display.V1.FormattedValue": "ACHERES - ACHERES - V90 2493 - SEQENS",
                    "qua_ensembleimmobilier.qua_programmedevente@Microsoft.Dynamics.CRM.lookuplogicalname": "qua_programmedevente",
                    "qua_ensembleimmobilier.qua_programmedevente": "f5226e23-f44d-ec11-8c62-002248811d98",
                    "qua_ensembleimmobilier.statecode@OData.Community.Display.V1.AttributeName": "statecode",
                    "qua_ensembleimmobilier.statecode@OData.Community.Display.V1.FormattedValue": "Actif",
                    "qua_ensembleimmobilier.statecode": 0,
                    "qua_ensembleimmobilier.qua_ville@OData.Community.Display.V1.AttributeName": "qua_ville",
                    "qua_ensembleimmobilier.qua_ville": "ACHERES",
                    "qua_ensembleimmobilier.qua_mandant@OData.Community.Display.V1.AttributeName": "qua_mandant",
                    "qua_ensembleimmobilier.qua_mandant@OData.Community.Display.V1.FormattedValue": "SEQENS",
                    "qua_ensembleimmobilier.qua_mandant@Microsoft.Dynamics.CRM.lookuplogicalname": "account",
                    "qua_ensembleimmobilier.qua_mandant": "bebca4ef-a98c-eb11-b1ac-000d3a2e7a8b"
                },
            ],
            "pagingCookie": "<cookie pagenumber=\"2\" pagingcookie=\"42\" istracking=\"False\" />",
            "isMoreRecords": false,
            "pageNumber": 1,
            "totalCount": 2
        }
    }
}

export const MOCK_GET_BI: requestMock = {
    id: FETCH_GET_BI,
    method: 'get',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+/,
    response: {
        data: {
            qua_bienimmobilierid: "4bbf6634-964f-ec11-8c62-002248811d98",
            qua_programmedeventeid: "e736682f-f44d-ec11-8c62-002248811d98",
            qua_classedpeid: null,
            qua_name: "000743",
            qua_nature: 975250000,
            qua_nature_label: "Maison",
            qua_typologie: 975250004,
            qua_typologie_label: "T5",
            qua_etage: 975250000,
            qua_etage_label: "0",
            qua_destination: 975250000,
            qua_destination_label: "Habitation",
            qua_etat: 975250000,
            qua_etat_label: "Cessible",
            qua_occupevacant: false,
            qua_occupevacant_label: "Vacant",
            qua_surfacehabitable: 72,
            qua_surfacehabitable_label: "72,00",
            qua_copropriete_boolean: false,
            qua_copropriete_boolean_label: "Non",
            qua_chargesmensuellesdecopropriete_base: null,
            qua_chargesmensuellesdecopropriete: null,
            qua_chargesannuellescopropriete_base: null,
            qua_chargesannuellescopropriete: null,
            qua_loicarrez: null,
            qua_nomdanstatlocatif: null,
            qua_isdpeencours: false,
            qua_valeurdudpe: 1,
            qua_datefindelaipriorite: "2022-01-31T00:00:00",
            qua_datefindelaipriorite_label: "31/01/2022",
            qua_datedelapublicitelegale: "2021-12-31T00:00:00",
            qua_datedelapublicitelegale_label: "31/12/2021",
            qua_datedesortiedulocataire: "2021-12-15T23:00:00Z",
            qua_datedesortiedulocataire_label: "15/12/2021",
            qua_datereception: "2021-11-30T23:00:00Z",
            qua_datereception_label: "30/11/2021",
            "ensemble.qua_commune": "a732e838-528f-e711-80f0-3863bb357dc8",
            "ensemble.qua_commune_label": "91090",
            "ensemble.qua_adresse1": "4 ALLEE DE LA CROIX AU BERGER",
            "ensemble.qua_adresse2": null,
            "ensemble.qua_ville": "LISSES",
            "ensemble.qua_mandant": "bebca4ef-a98c-eb11-b1ac-000d3a2e7a8b",
            "ensemble.qua_mandant_label": "SEQENS",
            prices: [
                {
                    qua_prixid: "e40984f9-9252-ec11-8c62-002248811d98",
                    qua_montant: 142000.0,
                    qua_typedacquereurpotentiel: "1efdd4ec-fabe-eb11-bacc-000d3a441033",
                    qua_typedacquereurpotentiel_label: "Type acquéreur 1"
                }
            ]
        }
    },
};

export const MOCK_GET_BI_LOCAPRINC: requestMock = {
    id: FETCH_GET_BI_LOCAPRINC,
    method: 'get',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+\/locaprinc$/,
    response: {
        data: {
            qua_bienimmobilierid: "00000000-0000-0000-0000-000000000000",
            contactid: "49ece14c-5246-ec11-8c62-6045bd8dbbd9",
            qua_civilite: 975250000,
            qua_civilite_label: "Monsieur",
            firstname: "Frédéric",
            lastname: "PIERRE",
            fullname: "Frédéric PIERRE",
            new_datedupremierbaildulocataire: null,
            new_datedupremierbaildulocataire_label: null,
            mobilephone: "0626053562",
            telephone1: "0160862759",
            emailaddress1: "sunitapierre1994@gmail.com",
            qua_age: null,
            birthdate: null,
            birthdate_label: null,
            qua_situationfamiliale: 975250001,
            qua_situationfamiliale_label: "Marié(e)",
            qua_nombredepersonnesacharge: null,
            qua_contentieuxencours: false,
            qua_contentieuxencours_label: "Non",
            qua_montantcontentieux: null,
            properties: [],
            principals: []
        }
    }
}

export const MOCK_GET_BI_DOSSIERS: requestMock = {
    id: FETCH_GET_BI_DOSSIERS,
    method: 'get',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+\/dossier$/,
    response: {
        data: [{
            qua_statut: 975250000,
            qua_name: 'DV-2021-3954',
            createdon_label: '23/05/2021',
            statuscode_label: 'Cloturé',
            qua_prixdeventetotal: 139782,
            qua_compositionfamiliale: 4,
            qua_revenuefiscalreferencefoyermoins2: 48654,
            mso_rfryear: 2020,
            qua_datedannulation_label: '20/01/2021',
            qua_datesignatureacteauthentique_label: '17/02/2020',
            qua_datesignaturedelavantcontrat_label: '05/04/2019',
            qua_datetransmissionnotaire_label: '02/05/2017',
            qua_datedefindudelaisru_label: '13/07/2016',
            qua_dateenvoisru_label: '26/09/2015',
            qua_acquereurs: [
                {
                    qua_acquereurid: 'bb22ca0c-fbb7-eb11-8236-000d3a480ab0',
                    contact_fullname: 'Djibril AMINATA',
                    contact_age: 43,
                    qua_statutjuridique_label: null,
                },
                {
                    qua_acquereurid: 'e9b18666-46b2-eb11-8236-000d3a4592c3',
                    contact_fullname: 'Patrick BOISSON DE CHAZOURNES',
                    contact_age: 39,
                    qua_statutjuridique_label: null
                },
            ],
            autres_biens_acquis: [
                {
                    bienimmobilier_name: '113476',
                    bienimmobilier_destination_label: 'Stationnement',
                    bienimmobilier_nature_label: 'Emplacement simple'
                },
                {
                    bienimmobilier_name: '354.456.978',
                    bienimmobilier_destination_label: 'Divers',
                    bienimmobilier_nature_label: 'Cave'
                },
            ],
            qua_statutjuridiqueacquereurs: [
                {
                    qua_statutjuridique_label: "Locataire occupant",
                    qua_lignedossierventeid: "79ccc211-84ae-eb11-8236-00224881e6d7",
                    qua_acquereurid: 'bb22ca0c-fbb7-eb11-8236-000d3a480ab0',
                },
                {
                    qua_statutjuridique_label: "Tier",
                    qua_lignedossierventeid: "79ccc211-84ae-eb11-8236-00224881e6d7",
                    qua_acquereurid: 'e9b18666-46b2-eb11-8236-000d3a4592c3'
                },
            ]
        },
        {
            qua_statut: 975250001,
            qua_name: 'DV-2021-3944',
            createdon_label: '23/03/2021',
            statuscode_label: 'Ne souhaite plus acheter',
            qua_prixdeventetotal: 139782,
            qua_compositionfamiliale: 3,
            qua_revenuefiscalreferencefoyermoins2: 75400,
            mso_rfryear: 2020,
            qua_acquereurs: [],
            autres_biens_acquis: [],
        },
        {
            qua_statut: 975250001,
            qua_name: 'DV-2021-3934',
            createdon_label: '03/01/2021',
            statuscode_label: 'Refus de prêt',
            qua_prixdeventetotal: 139782,
            qua_compositionfamiliale: 0,
            qua_revenuefiscalreferencefoyermoins2: 25100,
            mso_rfryear: 2020,
            qua_acquereurs: [],
            autres_biens_acquis: [],
        },]
    },
};

export const MOCK_GET_BI_ENTRETIENS: requestMock = {
    id: FETCH_GET_BI_ENTRETIENS,
    method: 'get',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+\/entretien$/,
    response: {
        data: [
            {
                qua_etudepersonnaliseeid: "ab78bb13-4383-4614-88f2-341f872bb26b",
                qua_conclusionentretienlocataire: {
                    qua_dateentretien: "2021-05-06T00:00:00",
                    qua_dateentretien_label: "06/05/2021",
                    qua_scoring: 975250002,
                    qua_scoring_label: "2 - Forte chance d'achat",
                    qua_niveaudinteret: 975250001,
                    qua_niveaudinteret_label: "Intérêt achat à 6 mois",
                    qua_observationslocataire: "Je suis le locataire et j'écris mon commentaire",
                    qua_observationsquadral: "Quadral donne son avis"
                }
            }
        ]
    },
};

export const MOCK_PUT_BI: requestMock = {
    id: FETCH_PUT_BI,
    method: 'put',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+$/,
    response: {
        data: 'C\'est okeysh'
    },
};

export const MOCK_PUT_BI_LOCAPRINC: requestMock = {
    id: FETCH_PUT_BI_LOCAPRINC,
    method: 'put',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+\/locaprinc$/,
    response: {
        data: 'C\'est okeysh',
    },
};

export const MOCK_PUT_BI_VACANT: requestMock = {
    id: FETCH_PUT_BI_VACANT,
    method: 'put',
    pattern: /^\/bienimmo\/[0-9A-Fa-f-]+\/vacant$/,
    response: {
        data: 'C\'est okeysh',
    },
};

export const MOCK_GET_SEARCH_PG: requestMock = {
    id: FETCH_GET_SEARCH,
    method: 'get',
    pattern: /^\/program\/search\//,
    response: {
        data: [
            { qua_programmedeventeid: '55b4bb62-3cfc-e711-8101-3863bb344ad0', qua_name: 'ALFORTVILLE - - 380 - SEQENS', nomdumandantname: 'SEQENS', ensembleimmobilierville: 'ALFORTVILLE' },
            { qua_programmedeventeid: '3a58826d-7f41-e911-a955-000d3a454330', qua_name: 'AMILLY - - - 3F CENTRE VAL DE LOIRE', nomdumandantname: '3F CENTRE VAL DE LOIRE', ensembleimmobilierville: 'AMILLY' },
            { qua_programmedeventeid: '06336a8a-5d9a-e811-8122-1458d04316b0', qua_name: 'ANGERS - - - FONCIERE DI 01-2005 (AFL)', nomdumandantname: 'FONCIERE DI 01-2005 (AFL)', ensembleimmobilierville: 'ANGERS' },
            { qua_programmedeventeid: 'fd0a5700-a087-e911-a95e-000d3a454e11', qua_name: 'ANNECY LE VIEUX - - - IN\'LI AURA', nomdumandantname: 'IN\'LI AURA', ensembleimmobilierville: 'ANNECY LE VIEUX' },
            { qua_programmedeventeid: 'cb5bcb78-f8d9-e911-a812-000d3a252137', qua_name: 'ARGENTAT - - - LAGUNE C27', nomdumandantname: 'LAGUNE C27', ensembleimmobilierville: 'ARGENTAT' },
        ]
    },
}

export const MOCK_GET_SEARCH_BI: requestMock = {
    id: FETCH_GET_SEARCH,
    method: 'get',
    pattern: /^\/bienimmo\/search\//,
    response: {
        data: [
            { qua_bienimmobilierid: '5f253785-6a23-eb11-a813-000d3a253e09', qua_name: 'Z112L-0002', qua_typologie_label: 'T4', qua_etage_label: '1', qua_nature_label: 'Appartement', programmedeventename: 'LYON -  - Z111L & Z112L - IMMOBILIERE RHONE ALPES' },
            { qua_bienimmobilierid: '6e774f72-b2a1-eb11-b1ac-000d3abf85fc', qua_name: 'Z2896004-7', qua_typologie_label: 'T4', qua_etage_label: null, qua_nature_label: 'Maison', programmedeventename: 'BROMMAT -  -  - LAGUNE GARDEN' },
            { qua_bienimmobilierid: 'd1fee535-05fd-e711-80fc-3863bb357dc8', qua_name: 'Z3048001', qua_typologie_label: 'T9', qua_etage_label: '0', qua_nature_label: 'Maison', programmedeventename: 'TULLE -  -  - LAGUNE C27' },
            { qua_bienimmobilierid: '31f1a16c-1912-eb11-a813-000d3a2cbc56', qua_name: 'Z2960005', qua_typologie_label: 'T5', qua_etage_label: null, qua_nature_label: 'Maison', programmedeventename: 'MUR DE BARREZ -  -  - LAGUNE GARDEN' },
            { qua_bienimmobilierid: '57253785-6a23-eb11-a813-000d3a253e09', qua_name: 'Z111L-0011', qua_typologie_label: 'T3', qua_etage_label: '3', qua_nature_label: 'Appartement', programmedeventename: 'LYON -  - Z111L & Z112L - IMMOBILIERE RHONE ALPES' },
        ]
    },
}

export const mocks = [
    MOCK_GET_CONTACT,
    MOCK_GET_COMMUNES,
    MOCK_PUT_CONTACT,
    MOCK_POST_CONTACT,
    MOCK_GET_INDICATORS,
    MOCK_POST_BIS,
    MOCK_GET_BI,
    MOCK_GET_BI_DOSSIERS,
    MOCK_GET_BI_ENTRETIENS,
    MOCK_PUT_BI,
    MOCK_PUT_BI_LOCAPRINC,
    MOCK_PUT_BI_VACANT,
    MOCK_GET_SEARCH_PG,
    MOCK_GET_SEARCH_BI,
];
