import axios, { AxiosRequestConfig } from 'axios';
import store from '../store';
import { notify } from "@kyvg/vue3-notification";
import * as MSAL from './msal';
import { START_LOADING, FINISH_LOADING } from '../store/mutations';
import * as Config from './config';
import * as Mock from './fetch-mocker';
import * as Mocks from './fetch-mocks';


const api = axios.create({
    baseURL: Config.API_HOST + '/api/MyQuadralPro/',
    headers: {
        'Accept': 'application/json',
    },
});
const apiSignUp = axios.create({
    baseURL: Config.API_HOST + '/api/MyQuadralProSignUp/',
    headers: {
        'Accept': 'application/json',
    },
    withCredentials: true,
});


// Mocking

[api, apiSignUp].forEach((client) => {
    client.interceptors.request.use(config => {
        if (Mock.mockingEnabled && Mock.isUrlMocked(config)) {
            console.log('Axios mocking ' + config.method.toUpperCase() + ' ' + config.url);
            return Mock.getMockError(config);
        }
        return config;
    }, error => Promise.reject(error));
});

[api, apiSignUp].forEach((client) => {
    client.interceptors.response.use(response => response, error => {
        if (Mock.isMockError(error)) {
            return Mock.getMockResponse(error);
        }
        return Promise.reject(error);
    });
});

Mocks.mocks.forEach(mock => {
    if (Mocks.isMock(mock.id)) {
        Mock.addMock(mock.pattern, mock.response, mock.method);
    }
});

Mock.enableMocking(true);


// Loading

const startLoading = () => {
    store.commit('loading/' + START_LOADING);
};
const finishLoading = () => {
    store.commit('loading/' + FINISH_LOADING);
};

[api, apiSignUp].forEach((client) => {
    client.interceptors.request.use(config => {
        startLoading();
        return config;
    }, error => {
        finishLoading();
        return Promise.reject(error);
    });
});
[api, apiSignUp].forEach((client) => {
    client.interceptors.response.use(response => {
        finishLoading();
        return response;
    }, error => {
        finishLoading();
        return Promise.reject(error);
    });
});


// Azure tokens with login fallback

const clientScopes = [{ client: api, scope: Config.GRAPH_API }];
clientScopes.forEach(({ client, scope }) => {
    client.interceptors.request.use((config: AxiosRequestConfig) => {
        return new Promise((resolve, reject) => {
            MSAL.getAccess([scope], (response) => {
                const accessToken = response.accessToken;
                config.headers['Authorization'] = 'Bearer ' + accessToken;
                return resolve(config);
            });
        });
    });
});


// API error notifs

api.interceptors.response.use(response => response, reason => {
    if (reason.response.status !== 404) {
        notify({ type: 'error', text: reason.response.data });
    }
    return Promise.reject(reason);
});
apiSignUp.interceptors.response.use(response => response, reason => {
    let text = reason.response.data;
    if (typeof reason.response.data === 'object') {
        text = reason.response.data.map(error => error.errorMessage).join('<br>');
    }
    notify({ type: 'error', text });
    return Promise.reject(reason);
});


// Fetchers

export function fetchAPI(route, config: any = {}) {
    config.url = route;
    config.headers = config.headers || {};
    return api.request(config);
}
export function fetchAPISignUp(route, config: any = {}) {
    config.url = route;
    config.headers = config.headers || {};
    return apiSignUp.request(config);
}
