<template>
  <div class="header right0">
    <button v-if="backButton" @click="$router.go(-1)" class="btn">
      <fa icon="arrow-left" />
    </button>
    <h2 class="flex-grow-1 text-truncate">
      <slot></slot>
    </h2>
    <span class="d-none d-lg-block header_entity"><slot name="entity"></slot></span>
  </div>
</template>

<script>
export default {
  props: {
    backButton: {
      default: true,
    },
  },
};
</script>
