<style lang="scss">
@import "@vueform/multiselect/themes/default";
@import "./Search";
</style>

<template>
  <Multiselect
    placeholder="-"
    noOptionsText="Tapez au moins 3 lettres pour lancer la recherche"
    noResultsText="Aucun résultat trouvé"
    :options="search"
    :searchable="true"
    :caret="false"
    :resolveOnLoad="false"
    :filterResults="false"
    :delay="300"
    :minChars="3"
    :object="true"
    @select="searchSelect"
    ref="search"
    class="search"
  >
    <template #placeholder>
      <div class="multiselect-placeholder">Rechercher</div>
    </template>
    <template #option="{ option }">
      <span v-if="!option.type">{{ option.label }}</span>
      <span class="multiselect_suffix" v-if="!option.type && option.suffix">{{
        option.suffix
      }}</span>

      <div
        v-if="option.type === BIENIMMO_TYPE"
        class="d-flex align-items-center"
      >
        <div class="text-truncate">
          <fa v-if="option.type" icon="home" class="me-1" />
          <span class="me-1">{{ option.data.qua_nature_label }} &bull;</span>
          <span class="text-truncate">Réf. {{ option.label }}</span>
        </div>
        <MiniBI
          class="ms-auto d-none d-sm-block"
          :bienimmo="option.data"
          :optional="true"
        />
      </div>
      <div
        v-if="option.type === BIENIMMO_TYPE"
        class="text-truncate text-muted"
      >
        {{ option.data.programmedeventename }}
      </div>

      <div
        v-if="option.type === PROGRAM_TYPE"
        class="d-flex align-items-center"
      >
        <fa icon="building" class="me-1" />
        <span class="text-truncate">{{ option.label }}</span>
      </div>
      <div v-if="option.type === PROGRAM_TYPE" class="d-flex text-muted">
        <div class="mr-1 text-truncate">{{ option.data.ensembleimmobilierville }}</div>
        <div class="ms-auto text-truncate">
          {{ option.data.nomdumandantname }}
        </div>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "@vueform/multiselect";

const PROGRAM_TYPE = 1;
const BIENIMMO_TYPE = 2;

export default {
  components: { Multiselect },
  data() {
    return {
      PROGRAM_TYPE,
      BIENIMMO_TYPE,
    };
  },
  methods: {
    search(query) {
      return this.$store
        .dispatch("common/search", query)
        .then(({ programs, bienimmos }) => {
          return [
            {
              label: "Biens immobiliers",
              disabled: true,
              value: "",
              suffix: bienimmos.length ? "" : "Aucun résultat trouvé",
            },
          ]
            .concat(
              bienimmos.map((bienimmo) => ({
                value: bienimmo.qua_bienimmobilierid,
                label: bienimmo.qua_name,
                data: bienimmo,
                type: BIENIMMO_TYPE,
              }))
            )
            .concat([
              {
                label: "Programmes de ventes",
                disabled: true,
                value: "",
                suffix: programs.length ? "" : "Aucun résultat trouvé",
              },
            ])
            .concat(
              programs.map((program) => ({
                value: program.qua_programmedeventeid,
                label: program.qua_name,
                data: program,
                type: PROGRAM_TYPE,
              }))
            );
        });
    },
    searchSelect({ type, value }) {
      this.$refs.search.clear();
      this.$router.push({
        name: type === PROGRAM_TYPE ? "program" : "bien-immo",
        params: { id: value },
      });
    },
  },
};
</script>
