import * as Constants from './crm-constants';
import { dependencies } from './qua_bienimmobilier_dependentoptionsetconfig.js';
import {
    apiMapping,
    apiMappingPrefix,
} from './interfaces';


// const xmlBuilder = new Builder();


/* API internal field mapping */


const ensemblePrefix: apiMappingPrefix = { api: 'qua_ensembleimmobilier.', crm: 'qua_ensembleimmobilier.' };
const locaprincPrefix: apiMappingPrefix = { api: 'contact.', crm: 'contact.' };
const conclusion: apiMappingPrefix = { api: 'qua_etudepersonnalisee.', crm: 'qua_etudepersonnalisee.' };


export const BIENIMMO_API_MAPPING = [
    { api: 'qua_nature', format: true },
    { api: 'qua_nature', format: true },
    { api: 'qua_etage', format: true },
    { api: 'qua_etat', format: true },
    { api: 'qua_occupevacant', format: true },
    { api: 'qua_typologie', format: true },
    { api: 'qua_copropriete_boolean', format: true },
    { api: 'qua_datedesortiedulocataire', format: true },
    { api: 'qua_datefindelaipriorite', format: true },
    { api: 'qua_datedelapublicitelegale', format: true },
    { api: 'qua_datereception', format: true },
    { api: 'qua_classedpeid', format: true },
    { api: 'qua_classeges', format: true },
    { api: 'qua_programmedeventeid', lookup: true },
    { prefix: ensemblePrefix, api: 'qua_adresse1' },
    { prefix: ensemblePrefix, api: 'qua_adresse2' },
    { prefix: ensemblePrefix, api: 'qua_commune', format: true },
    { prefix: ensemblePrefix, api: 'qua_ville' },
    { prefix: ensemblePrefix, api: 'qua_programmedevente', format: true },
    { prefix: ensemblePrefix, api: 'qua_mandant', format: true },
    { prefix: locaprincPrefix, api: 'fullname', format: true },
    { prefix: locaprincPrefix, api: 'new_datedupremierbaildulocataire', format: true },
    { prefix: conclusion, api: 'qua_scoring', format: true },
];


export const BIENIMMO_LOCAPRINC_API_MAPPING = [
    { api: 'qua_civilite', format: true },
    { api: 'birthdate', format: true },
    { api: 'qua_situationfamiliale', format: true },
    { api: 'new_datedupremierbaildulocataire', format: true },
];


export const PROGRAM_API_MAPPING = [
    { api: 'statuscode', format: true },
    { api: 'qua_datelancementcommercialisation', format: true },
    { api: 'mso_codepostalid', lookup: true, format: true },
    { api: 'qua_nomdumandant', lookup: true, format: true },
];


export function mapCrmToApi(record, mapping: apiMapping[]) {
    mapping.forEach(fieldMapping => {
        let crmFieldName = (fieldMapping.crm || fieldMapping.api);
        crmFieldName = (fieldMapping.prefix ? fieldMapping.prefix.crm : '') + crmFieldName;
        crmFieldName = fieldMapping.lookup ? `_${crmFieldName}_value` : crmFieldName;

        let apiFieldName = fieldMapping.api;
        apiFieldName = (fieldMapping.prefix ? fieldMapping.prefix.api : '') + apiFieldName;
        record[apiFieldName] = record[crmFieldName];

        if (fieldMapping.format) {
            record[apiFieldName + '_label'] = crmFormat(record, crmFieldName);
        }
    });
    return record;
}


/* Functions */


export function formattedField(field) {
    return `${field}${Constants.ANNOTATION_PREFIX}.${Constants.ANNOTATION_FORMAT}`;
}


export function extrudeAliasedFields(obj, alias) {
    if (!obj) return {};
    return Object.keys(obj)
        .filter(key => key.startsWith(alias))
        .reduce((field, key) => {
            field[key.substring(alias.length)] = obj[key];
            return field;
        }, {});
}


export function crmFormat(item, field, undefinedLabel = null) {
    if (!item) return null;
    const prop = formattedField(field);
    return item[prop] || undefinedLabel;
}


export function getBIDependencies(sourceField, targetField, sourceValue) {
    const dependency = dependencies.DependentOptionSetConfig.ParentField.find(parent => parent['-id'] === sourceField && parent.DependentField['-id'] === targetField);
    if (!dependency) throw Error(`No dependency found from ${sourceField} to ${targetField}`);
    const targetValues = dependency.Option.find(option => parseInt(option['-value']) === sourceValue);
    if (!targetValues || !targetValues.ShowOption) return [];
    return targetValues.ShowOption.map(showOption => parseInt(showOption['-value']));
}
