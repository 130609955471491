import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import Header from './views/common/Header.vue'
import MiniBI from './views/bien-immo/MiniBI.vue'
import './utils/icons'
import './utils/msal'

if (window.top) {
    const app = createApp(App)
    app.use(router)
    app.use(store)
    app.use(Notifications)
    app.component('fa', FontAwesomeIcon)
    app.component('Header', Header)
    app.component('MiniBI', MiniBI)

    app.mount('#app')
}
