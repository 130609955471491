<template>
  <div>
    <Header>MENTIONS LÉGALES - CGU</Header>
    <div class="p-3">
      <h1>
        Conditions générales d'utilisation du site internet myquadralpro.fr
      </h1>
      <div class="text-center"><i>Mise à jour le 24/01/2022</i></div>

      <div>
        <h2>PRÉAMBULE</h2>
        <p>
          Les présentes conditions générales d'utilisation (CGU) sont conclues
          entre :
        </p>
        <ul>
          <li>Le gérant du site internet, ci-après désigné « l’éditeur »,</li>
          <li>
            Toute personne physiques ou morales souhaitant accéder au site et à
            ses services, ci-après appelé « l’utilisateur »
          </li>
        </ul>
        <p>
          Toute connexion au site est subordonnée au respect des présentes
          conditions.
        </p>
        <p>
          Pour l’utilisateur, le simple accès au site de l’éditeur à l’adresse
          URL suivante
          <a href="https://www.myquadralpro.fr/"
            >https://www.myquadralpro.fr/</a
          >
          implique l’acceptation de l’ensemble des conditions décrites ci-après.
        </p>

        <h2>ARTICLE 1 – PRINCIPES</h2>
        <p>
          Les présentes conditions générales d'utilisation définissent et
          encadrent les modalités d’accès et de navigation sur le site Internet,
          et déterminent les droits et les obligations respectifs de
          l’utilisateur et de l’éditeur dans le cadre de l’utilisation du site
          myquadralpro.fr et de ses services.
        </p>
        <p>
          Le site Internet
          <a href="https://www.myquadralpro.fr/"
            >https://www.myquadralpro.fr/</a
          >
          est un service assuré par la société Quadral Transactions SAS au
          capital de 696 193,00 €<br />
          Siège social : 24 ter rue du Général De Gaulle 57050, LE BAN ST
          MARTIN, France<br />
          URL du site :
          <a href="https://www.myquadralpro.fr/">https://www.myquadralpro.fr/</a
          ><br />
          Numéro de téléphone : 03 87 37 77 25
        </p>
        <p>
          La société Quadral Transactions est représentée par Mme GRANDJEAN Maud
          en tant que Directrice Générale.
        </p>
        <p>
          Ce site est hébergé par Microsoft Azure France (région France centre)
          :<br />
          Microsoft France<br />
          37 Quai du Président Roosevelt<br />
          92130 ISSY-LES-MOULINEAUX
        </p>
        <h2>
          ARTICLE 2 - ÉVOLUTION ET DURÉE DES CGU (CONDITIONS GÉNÉRALES
          D’UTILISATION)
        </h2>
        <p>
          Le contrat produit ses effets à l'égard de l'utilisateur à compter du
          début de l’utilisation du service.
        </p>
        <p>
          Les présentes conditions générales d'utilisation sont conclues pour
          une durée durant laquelle l’utilisateur dispose d’un compte
          utilisateur sur la plate-forme myquadralpro.fr.
        </p>
        <p>
          La durée de conservation en archives courantes correspond à la durée
          durant laquelle l’utilisateur dispose d’un compte utilisateur sur la
          plate-forme myquadralpro.fr.
        </p>
        <p>
          Quadral Transactions conservera les données personnelles conformément
          à la durée nécessaire aux finalités pour lesquelles elles sont
          collectées, soit pendant la durée du contrat augmentée des
          prescriptions légales, mais également pour assurer le respect des
          obligations légales ou réglementaires auxquelles la société Quadral
          Transactions est tenue.
        </p>
        <p>
          Le site myquadralpro.fr se réserve le droit de modifier les clauses de
          ces conditions générales d’utilisation ainsi que toute procédure ou
          autre élément constitutif des prestations par le biais du présent
          site.
        </p>
        <h2>ARTICLE 3 - ACCÈS AU SITE</h2>
        <p>
          La société Quadral Transactions s’engage à œuvrer pour assurer la
          sécurisation et la confidentialité des données traitées sur la
          plate-forme myquadralpro.fr, dans l’état de l’art, de maintenir
          l’accès au site, et d’en assurer le bon fonctionnement.
        </p>
        <p>
          L’utilisation du site est conditionnée à l’inscription d’un
          utilisateur.
        </p>
        <p>
          Pour procéder à l’inscription, l’utilisateur doit remplir tous les
          champs obligatoires ; à défaut l’inscription ne pourra être effective.
        </p>
        <p>
          Les utilisateurs garantissent et déclarent sur l’honneur que toutes
          les informations communiquées sur le site, notamment lors de leur
          inscription, sont exactes et conformes. Ils s’engagent à mettre à jour
          leurs informations personnelles à partir de la page dédiée à ces
          dernières et disponible dans leur compte.
        </p>
        <p>
          Tout utilisateur enregistré dispose d’un identifiant et d’un mot de
          passe. Ces derniers sont strictement personnels et confidentiels et ne
          devront en aucun cas faire l’objet d’une communication à des tiers
          sous peine de suppression du compte de l’utilisateur enregistré
          contrevenant. Chaque utilisateur enregistré est personnellement
          responsable du maintien de la confidentialité de son identifiant et de
          son mot de passe. L’éditeur ne sera an aucun cas tenu responsable de
          l’usurpation d’identité d’un utilisateur. Si un utilisateur suspecte
          une fraude à n’importe quel moment, il devra contacter l’éditeur dans
          les plus brefs délais, afin que ce dernier puisse prendre les mesures
          nécessaires et régulariser la situation.
        </p>
        <p>
          Chaque utilisateur ne peut être titulaire que d’un compte sur le site.
        </p>
        <p>
          L’utilisateur créé son propre mot de passe. Il est fortement
          recommandé d’utiliser un jeu enrichi de caractères (minuscules,
          majuscules, chiffres, signes spéciaux), d’utiliser huit caractères au
          moins, de ne pas utiliser prénom, nom, raison sociale de l’organisme,
          de changer régulièrement de mot de passe.
        </p>
        <p>
          L’utilisateur s’engage à utiliser le site conformément à sa
          destination, a l’obligation de ne pas tenter de nuire au bon
          fonctionnement du site
        </p>
        <p>
          En cas de non-respect des CGU, notamment la création de plusieurs
          comptes pour une seule personne ou encore la fourniture de fausse
          informations, l’éditeur se réserve le droit de procéder à la
          suppression temporaire ou définitive de tous les comptes crées par
          l’utilisateur contrevenant.
        </p>
        <p>
          La suppression du compte entraine la perte définitive de tous les
          avantages et services acquis sur le site.
        </p>
        <p>
          En cas de suppression d’un compte par l’éditeur pour manquement aux
          devoirs et obligations énoncés dans les CGU, il est formellement
          interdit à l’utilisateur contrevenant de se réinscrire sur le site
          directement, par le biais d’une autre adresse électronique ou par
          personne interposée sans l’autorisation expresse de l’éditeur.
        </p>
        <p>
          Tout utilisateur ayant accès à internet peut accéder gratuitement et
          depuis n’importe où au site myquadralpro.fr. Les frais supportés par
          l’utilisateur pour y accéder (connexion internet, matériel
          informatique, etc.) ne sont pas à la charge de l’éditeur.
        </p>
        <p>
          Le site et ses différents services peuvent être interrompus ou
          suspendus par l’éditeur, notamment à l’occasion d’une maintenance,
          sans obligation de préavis ou de justification.
        </p>
        <p>L'utilisateur du site a accès aux services suivants :</p>
        <ul>
          <li>
            Consultation des données relatives à la prestation de
            commercialisation pour laquelle Quadral Transactions a été mandaté
          </li>
          <ul>
            <li>Suivi du patrimoine en vente</li>
            <li>Suivi des ventes et de l’objectif annuel</li>
          </ul>
          <li>Mise à jour de son compte utilisateur</li>
          <li>
            Mise à jour des données relatives au biens immobiliers mis en vente
            par l’utilisateur et pour lesquels Quadral Transactions a été
            mandaté
          </li>
          <li>
            Mise à jour des données relative aux locataires qui occupent ces
            mêmes biens immobiliers
          </li>
          <li>Déclaration de vacance de biens immobiliers</li>
        </ul>
        <p>
          L’ensemble des données consultées proviennent de l’outil de gestion de
          la société Quadral Transactions (Microsoft Dynamics 365). Ces données
          sont actualisées en quasi temps réel à l’exception des indicateurs
          présents sur la page d’accueil et sur les programmes de vente calculés
          chaque nuit.
        </p>
        <h2>ARTICLE 4 - RESPONSABILITÉS</h2>
        <p>
          La responsabilité de l'éditeur ne peut être engagée en cas de
          défaillance, panne, difficulté ou interruption de fonctionnement,
          empêchant l'accès au site ou à une de ses fonctionnalités.
        </p>
        <p>
          Le matériel de connexion au site utilisé est sous l'entière
          responsabilité de l'utilisateur qui doit prendre toutes les mesures
          appropriées pour protéger le matériel et les données notamment
          d'attaques virales par Internet. L'utilisateur est par ailleurs le
          seul responsable des sites et données qu'il consulte.
        </p>
        <p>
          L'éditeur ne pourra être tenu responsable en cas de poursuites
          judiciaires à l'encontre de l'utilisateur :
        </p>
        <ul>
          <li>
            Du fait de l'usage du site ou de tout service accessible via
            Internet ;
          </li>
          <li>
            Du fait du non-respect par l'utilisateur des présentes conditions
            générales d’utilisation.
          </li>
        </ul>
        <p>
          L'éditeur n'est pas responsable des dommages causés à l'utilisateur, à
          des tiers et/ou à l'équipement de l'utilisateur du fait de sa
          connexion ou de son utilisation du site et l'utilisateur renonce à
          toute action contre l'éditeur de ce fait.
        </p>
        <p>
          Si l'éditeur venait à faire l'objet d'une procédure amiable ou
          judiciaire à raison de l'utilisation du site par l'utilisateur, il
          pourra se retourner contre lui pour obtenir indemnisation de tous les
          préjudices, sommes, condamnations et frais qui pourraient découler de
          cette procédure.
        </p>
        <p>
          L’utilisateur est seul responsable de l’exploitation, de l’utilisation
          et de la publication des données qu’il peut obtenir sur la plate-forme
          myquadralpro.fr. L'éditeur ne saurait être tenu responsable des
          erreurs ou des omissions dans les informations diffusées par
          l’utilisateur ou des problèmes techniques rencontrés sur les sites
          vers lesquels sont établis des liens, ou de toute interprétation des
          informations publiées sur ces sites ainsi que des conséquences de leur
          utilisation.
        </p>
        <p>
          De même, l’utilisateur est seul responsable des modifications qu’il
          effectue sur son profil.
        </p>
        <h2>ARTICLE 5 - PROPRIÉTÉ INTELLECTUELLE</h2>
        <p>
          Tous les documents techniques, produits, photographies, textes, logos,
          dessins, vidéos, etc., sont soumis à des droits d'auteur et sont
          protégés par le Code de la propriété intellectuelle. Lorsqu'ils sont
          remis à nos clients, ils demeurent la propriété exclusive de Quadral
          Transactions, seul titulaire des droits de propriété intellectuelle
          sur ces documents, qui doivent lui être rendus à sa demande.
        </p>
        <p>
          Les utilisateurs s'engagent à ne faire aucun usage de ces documents,
          susceptibles de porter atteinte aux droits de propriété industrielle
          ou intellectuelle du fournisseur et s'engagent à ne les divulguer à
          aucun tiers, en dehors d'une autorisation expresse et préalable donnée
          par l'éditeur.
        </p>
        <h2>ARTICLE 6 - LIENS HYPERTEXTES</h2>
        <p>
          La mise en place par l'utilisateur de tous liens hypertextes vers tout
          ou partie du site est strictement interdite, sauf autorisation
          préalable et écrite de l'éditeur, sollicitée par mail à dpo@quadral.fr
          ou à l’adresse suivante :
        </p>
        <p>
          QUADRAL SAS – À l’attention du DPO – 3 Place du Roi George – 57000
          METZ.
        </p>
        <p>
          L'éditeur est libre de refuser cette autorisation sans avoir à
          justifier de quelque manière que ce soit sa décision. Dans le cas où
          l'éditeur accorderait son autorisation, celle-ci n'est dans tous les
          cas que temporaire et pourra être retirée à tout moment, sans
          obligation de justification à la charge de l'éditeur.
        </p>
        <p>
          Dans tous les cas, tout lien devra être retiré sur simple demande de
          l'éditeur.<br />
          Toute information accessible via un lien vers d'autres sites n'est pas
          sous le contrôle de l'éditeur qui décline toute responsabilité quant à
          leur contenu.
        </p>
        <p>
          La force majeure, un évènement extérieur imprévisible et
          insurmontable, exonère les parties de leurs responsabilités
          respectives. Cette disposition étant d’ordre public.
        </p>
        <h2>ARTICLE 7 - PROTECTION DES DONNÉES PERSONNELLES</h2>
        <p>
          L’enregistrement sur le site entraine le traitement des données à
          caractère personnel de l’utilisateur. Si l’utilisateur refuse le
          traitement de ses données, il lui est demandé de s’abstenir d’utiliser
          le site.
        </p>
        <p>
          Ce traitement des données à caractère personnel se fait dans le
          respect du Règlement Européen n°2016/679 du Parlement et du Conseil,
          dénommé Règlement Général de Protection des Données personnelles
          (RGPD), entré en application le 25 mai 2018.
        </p>
        <p>
          Cette règlementation a créé de nouvelles obligations et notamment :
        </p>
        <ul>
          <li>Des exigences de protection des données “dès la conception” ;</li>
          <li>De protection des données “par défaut” ;</li>
          <li>De documentation permanente des dispositifs de sécurité ;</li>
          <li>
            Ainsi que la nécessité de faire la revue, en tout temps, de
            l’effectivité de ces protections depuis la collecte jusqu’à la
            suppression définitive des données personnelles sur les systèmes.
          </li>
        </ul>
        <p>
          L’éditeur dans le cadre de l’ensemble de la relation avec
          l’utilisateur régie par ces conditions générales d’utilisation,
          s’engage expressément, par le présent article, à respecter les
          dispositions de cette nouvelle réglementation, relativement à la
          protection des données personnelles que l’éditeur serait amené à
          traiter pendant toute la durée de leur relation.
        </p>
        <h3>Données collectées</h3>
        <p>
          Des données à caractère personnel sont collectées sur ce site à
          l’ouverture ou à la modification d’un compte utilisateur. Lors de la
          création/mise à jour du compte de l'utilisateur sont collectées :
        </p>
        <ul>
          <li>Civilité ;</li>
          <li>Nom ;</li>
          <li>Prénom ;</li>
          <li>Adresse électronique ;</li>
          <li>N° de téléphone ;</li>
          <li>Adresse postale ;</li>
          <li>Société.</li>
        </ul>
        <h3>Utilisation des données personnelles</h3>
        <p>
          Les données personnelles collectées auprès des utilisateurs ont pour
          objectif la mise à disposition des services du site web, leur
          amélioration et le maintien d'un environnement sécurisé. Plus
          précisément, les utilisations sont les suivantes :
        </p>
        <ul>
          <li>Accès et utilisation du site web par l'utilisateur ;</li>
          <li>Gestion du fonctionnement et optimisation du site web ;</li>
          <li>
            Vérification, identification et authentification des données
            transmises par l'utilisateur ;
          </li>
          <li>Mise en œuvre d'une assistance utilisateurs ;</li>
          <li>
            Prévention et détection des fraudes, malwares (malicious softwares
            ou logiciels malveillants) et gestion des incidents de sécurité ;
          </li>
          <li>Gestion des éventuels litiges avec les utilisateurs ;</li>
        </ul>
        <h3>Partage des données personnelles avec des tiers</h3>
        <p>
          Les données collectées dans le cadre de la gestion de la plate-forme
          myquadralpro.fr sont destinées à l’usage exclusif des personnes
          habilitées de Quadral Transactions pour l'exercice de leurs missions.
          Elles sont à usage purement interne et ne font l’objet d’aucune
          communication, cession ou divulgation à des tiers sauf dans les cas
          suivants :
        </p>
        <ul>
          <li>
            Lorsque l'utilisateur autorise le site web d'un tiers à accéder à
            ses données ;
          </li>
          <li>
            Lorsque le site web recourt aux services de prestataires pour
            fournir l'assistance utilisateurs. Ces prestataires disposent d'un
            accès limité aux données de l'utilisateur, dans le cadre de
            l'exécution de ces prestations, et ont une obligation contractuelle
            de les utiliser en conformité avec les dispositions de la
            réglementation applicable en matière de protection des données à
            caractère personnel ;
          </li>
          <li>
            Si la loi l'exige, le site web peut effectuer la transmission de
            données pour donner suite aux réclamations présentées contre le site
            web et se conformer aux procédures administratives et judiciaires ;
          </li>
          <li>
            Si le site web est impliqué dans une opération de fusion,
            acquisition, cession d'actifs ou procédure de redressement
            judiciaire, il pourra être amené à céder ou partager tout ou partie
            de ses actifs, y compris les données à caractère personnel. Dans ce
            cas, les utilisateurs seraient informés, avant que les données à
            caractère personnel ne soient transférées à une tierce partie.
          </li>
        </ul>
        <p>
          La société Quadral Transactions s’assure que les sous-traitants
          présentent des garanties suffisantes quant à la mise en œuvre de
          mesures techniques et organisationnelles appropriées de manière à ce
          que le traitement réponde aux exigences du Règlement Européen sur la
          Protection des Données.
        </p>
        <p>
          Si les sous-traitants ne remplissaient pas leurs obligations en
          matière de protection des données, la société Quadral Transactions
          demeurera, conformément au Règlement Européen précité, pleinement
          responsable à l’égard de l’exécution par les autres sous-traitants de
          leurs obligations.
        </p>
        <h3>Sécurité et confidentialité</h3>
        <p>
          La société Quadral Transactions assure la mise en œuvre des mesures de
          sécurité et de confidentialité définies par la règlementation en
          vigueur, de manière appropriée au regard des risques présentés par le
          traitement.
        </p>
        <p>
          La société Quadral Transactions déclare et garantit que les serveurs
          où sont stockées les Données Personnelles transmises par l’utilisateur
          bénéficient des systèmes de sécurisation les plus performants et
          actuels au regard de l’état de l’art, de manière à ce que le
          traitement réalisé réponde aux exigences de la Réglementation
          applicable en matière de protection des Données Personnelles et
          garantisse la protection des droits des personnes concernées, y
          compris des mesures garantissant l’absence de destruction illicite, de
          perte accidentelle, d’altération, de diffusion ou d'accès non autorisé
          - notamment lorsque le traitement comporte des transmissions de
          Données dans un réseau - ainsi que de toute forme de traitement
          illicite.
        </p>
        <p>
          Plus spécifiquement, la société Quadral Transactions s’engage envers
          les utilisateurs à mettre en œuvre l’ensemble des mesures de sécurité
          permettant de garantir l’intégrité des Données Personnelles, la
          traçabilité de l’accès, la disponibilité des Données Personnelles à
          tout moment et la confidentialité desdites Données Personnelles.
        </p>
        <p>Ces mesures incluent notamment :</p>
        <ul>
          <li>
            La sécurité organisationnelle : organisation en matière de sécurité
            formellement définies et actées (déclinées à travers une Politique
            de Sécurité du Système d'Information ou PSSI), formation des
            personnels à la sécurité des Données Personnelles, mise en place et
            suivi des habilitations des accès aux systèmes d’information
            traitant des Données Personnelles, procédure de gestion et
            notification des incidents de sécurité ou des demandes
            contraignantes affectant les Données Personnelles, Plan de
            Continuité d’Activité (PCA), Plan de Secours Informatique (PSI),
            organisation de gestion de crise.
          </li>
          <li>
            La sécurité logique : durcissement des environnements informatiques,
            procédure de gestion des correctifs de sécurité, cloisonnement des
            architectures réseau et filtrage (firewalls), sondes anti-intrusion,
            contrôle d’accès par authentification, politique de mots de passe,
            protection des environnements informatiques sensibles par logiciel
            antivirus à jour (programmes et bases de signatures virales).
          </li>
          <li>
            Le chiffrement des Données Personnelles stockées par la société
            Microsoft Azure se fera conformément aux exigences actuelles de
            l'état de l'art en la matière (choix des procédés et des tailles de
            clés, gestion des secrets).
          </li>
          <li>
            La sécurisation des flux d'échanges de données à caractère personnel
            (chiffrement et authentification) se fera de manière à ce qu'ils ne
            puissent être exploités par un tiers non autorisé.
          </li>
          <li>
            Le respect du pseudonymat des Données Personnelles fournies par les
            utilisateurs : si le traitement confié à la société Microsoft Azure
            porte sur des données pseudonymisées, la société Microsoft Azure
            s'engage à ne pas chercher à lever le pseudonymat desdites données
            et à signaler sans délai au responsable de traitement toute
            réidentification (qu’elle soit accidentelle ou volontaire).
          </li>
          <li>
            La traçabilité des actions et la gestion des preuves : conservation
            des traces des activités sur le système informatique (accès aux
            services web, aux serveurs ftp sécurisés), durée de conservation des
            traces garantie (à titre indicatif, à minima 1 (un) an sauf
            contrainte réglementaire).
          </li>
          <li>
            La mise en place de procédures de contrôles pour s'assurer du
            maintien du niveau de sécurité dans le temps.
          </li>
        </ul>
        <p>
          Par ailleurs, les informations des utilisateurs pourront être
          transmises à des tiers sans leur accord express préalable afin
          d’atteindre les buts suivants :
        </p>
        <ul>
          <li>Respecter la loi</li>
          <li>
            Protéger toute personne contre des dommages corporels graves, voire
            la mort
          </li>
          <li>
            Lutter contre la fraude ou les atteintes portées à la société
            Quadral Transactions ou à ses utilisateurs
          </li>
          <li>Protéger les droits de la société Quadral Transactions</li>
        </ul>
        <p>
          La société Quadral Transactions ne peut en aucun cas être tenue
          responsable pour l’indisponibilité, qu’elle soit temporaire ou
          permanente du site Internet et bien qu’elle mette tout en œuvre tous
          ses moyens afin d’assurer en permanence le service, il se peut que
          celui-ci soit interrompu à tout moment. En outre, la société Quadral
          Transactions se réserve le droit, par un acte volontaire, de rendre le
          site indisponible afin de réaliser toute opération de mise à jour,
          d’amélioration ou de maintenance.
        </p>
        <h3>Transfert hors Union Européenne</h3>
        <p>
          Aucun transfert de Données à caractère personnel n’intervient en
          dehors de l’Union Européenne.
        </p>
        <p>
          Pour en savoir plus sur la sécurité mise en place par Microsoft Azure
          et les mesures prises dans le respect du RGPD, nous vous invitons à
          consulter la page
          <a
            href="https://azure.microsoft.com/fr-fr/global-infrastructure/data-residency/#overview"
            >https://azure.microsoft.com/fr-fr/global-infrastructure/data-residency/#overview</a
          >
        </p>
        <h3>Exercice des droits des personnes</h3>
        <p>
          La société Quadral Transactions s’acquittera de son obligation de
          donner suite aux demandes d’exercice des droits des personnes
          concernées : droit d’accès, de rectification, d’effacement et
          d’opposition, droit à la limitation du traitement, droit à la
          portabilité des données, droit de ne pas faire l’objet d’une décision
          individuelle automatisée.
        </p>
        <p>
          En application de la réglementation applicable aux données à caractère
          personnel, les utilisateurs peuvent faire leur demande en adressant un
          mail à dpo@quadral.fr ou à l’adresse suivante :
        </p>
        <p>
          QUADRAL SAS – À l’attention du DPO – 3 Place du Roi George – 57000
          METZ.
        </p>
        <p>
          Nous prendrons toutefois la précaution de vérifier votre identité
          avant de vous donner l’accès à vos données personnelles.
        </p>
        <p>
          Sous réserve d’un manquement aux dispositions ci-dessus, vous avez le
          droit d’introduire une réclamation auprès de la Commission Nationale
          de l’Informatique et des Libertés (CNIL) via www.cnil.fr.
        </p>
        <h3>Notification des violations de données à caractère personnel</h3>
        <p>
          Une Violation de Données désigne une violation de la sécurité
          entrainante, de manière accidentelle ou illicite, la destruction, la
          perte, l’altération, la divulgation non autorisée de Données
          Personnelles transmises par le Responsable de traitement, conservées
          ou traitées d’une autre manière, ou l’accès non autorisé à de telles
          données.
        </p>
        <p>
          La société Quadral Transactions s’engage à notifier aux utilisateurs
          tout incident de sécurité mettant en péril ou ayant impacté les
          données confiées. Plus spécifiquement, la société Quadral Transactions
          s’engage à notifier, sans délai et au plus tard 24 (vingt-quatre)
          heures à compter de la connaissance de l’évènement, de toute suspicion
          ou accès non autorisé aux données à caractère personnel transmises ou
          de manière générale, toute Violation de Données, en précisant les
          détails de celle-ci si la société Quadral Transactions dispose
          d’informations.
        </p>
        <p>
          La notification aux utilisateurs se fera par écrit à l’adresse mail
          préalablement communiquée par eux ;
        </p>
        <p>
          Par ailleurs, la société Quadral Transactions prendra toutes les
          mesures nécessaires pour empêcher l’introduction d’un programme
          malveillant ou tout autre code informatique destiné à effectuer un
          usage non autorisé d’un système d’information, et prendra les mesures
          appropriées en cas de détection d’un tel code.
        </p>
        <p>
          À cet effet, la société Quadral Transactions réalisera les tests
          appropriés et s’engage à vérifier les différents composants
          informatiques. Si un programme malveillant venait à être introduit, la
          société Quadral Transactions déterminera sa source et éradiquera ses
          conséquences, si cela est possible.
        </p>
        <h3>Évolution de la présente clause</h3>
        <p>
          Le site web se réserve le droit d'apporter toute modification à la
          présente clause relative à la protection des données à caractère
          personnel à tout moment. Si une modification est apportée à la
          présente clause de protection des données à caractère personnel, le
          site web s'engage à publier la nouvelle version sur son site. Le site
          web informera également les utilisateurs de la modification par
          messagerie électronique, dans un délai minimum de 15 jours avant la
          date d'effet. Si l'utilisateur n'est pas d'accord avec les termes de
          la nouvelle rédaction de la clause de protection des données à
          caractère personnel, il a la possibilité de supprimer son compte.
        </p>
        <h2>ARTICLE 8 - COOKIES</h2>
        <p>
          Pour permettre à ses utilisateurs de bénéficier d’une navigation
          optimale sur le site et d’un meilleur fonctionnement des différentes
          interfaces et applications, la société Quadral Transactions est
          susceptible d’implanter un cookie sur l’ordinateur de l’utilisateur.
          Ce cookie permet de stocker des informations relatives à la navigation
          sur le site.
        </p>
        <p>
          L’utilisateur autorise expressément la société Quadral Transactions à
          déposer sur le disque dur de l’utilisateur un fichier dit « cookie ».
        </p>
        <p>
          L’utilisateur dispose de la possibilité de bloquer, modifier la durée
          de conservation, ou supprimer ce cookie via l’interface de son
          navigateur. Si la désactivation systématique des cookies sur le
          navigateur de l’utilisateur l’empêche d’utiliser certains services ou
          fonctionnalités du site, ce dysfonctionnement ne saurait en aucun cas
          constituer un dommage pour l’utilisateur qui ne pourra prétendre à
          aucune indemnité de ce fait.
        </p>
        <h2>ARTICLE 9 - LOI APPLICABLE</h2>
        <p>
          En cas de litige, les parties tenteront de régler le conflit à
          l’amiable. A défaut de règlement amiable, l’éditeur doit respecter les
          règles d’attribution de compétence matérielle et territoriale. Par
          exemple : le consommateur doit avoir le choix entre le tribunal de son
          domicile ou le tribunal du lieu de la société éditrice du site. Le
          litige sera soumis à la compétence des tribunaux français. Les
          présentes conditions générales d'utilisation sont soumises à
          l'application du droit français.
        </p>
      </div>
    </div>
  </div>
</template>
