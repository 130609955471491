// Original XML file: qua_bienimmobilier_dependentoptionsetconfig.xml
// Converted with https://www.utilities-online.info/xmltojson

export const dependencies = {
    "DependentOptionSetConfig": {
        "-entity": "qua_bienimmobilier",
        "ParentField": [
            {
                "-id": "qua_destination",
                "-label": "Destination",
                "DependentField": {
                    "-id": "qua_nature",
                    "-label": "Nature",
                    "-self-closing": "true"
                },
                "Option": [
                    {
                        "-value": "975250000",
                        "-label": "Habitation",
                        "ShowOption": [
                            {
                                "-value": "975250000",
                                "-label": "Maison",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "Appartement",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250011",
                                "-label": "Chambre",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250001",
                        "-label": "Stationnement",
                        "ShowOption": [
                            {
                                "-value": "975250002",
                                "-label": "Aérien",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "Sous-sol",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "Extérieur",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250002",
                        "-label": "Tertiaire",
                        "ShowOption": [
                            {
                                "-value": "975250006",
                                "-label": "Local professionnel",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250007",
                                "-label": "Local commercial",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250017",
                                "-label": "Bureau",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250005",
                        "-label": "Industriel",
                        "ShowOption": [
                            {
                                "-value": "975250014",
                                "-label": "Usine",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250015",
                                "-label": "Hangar",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250003",
                        "-label": "Foncier",
                        "ShowOption": [
                            {
                                "-value": "975250008",
                                "-label": "Terrain",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250009",
                                "-label": "Jardin",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250004",
                        "-label": "Divers",
                        "ShowOption": [
                            {
                                "-value": "975250010",
                                "-label": "Cave",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "Cellier",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250012",
                                "-label": "Cour",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250016",
                                "-label": "Séchoir",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250013",
                                "-label": "Autre",
                                "-self-closing": "true"
                            }
                        ]
                    }
                ]
            },
            {
                "-id": "qua_nature",
                "-label": "Nature",
                "DependentField": {
                    "-id": "qua_typologie",
                    "-label": "Typologie",
                    "-self-closing": "true"
                },
                "Option": [
                    {
                        "-value": "975250000",
                        "-label": "Maison",
                        "ShowOption": [
                            {
                                "-value": "975250012",
                                "-label": "NC",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250000",
                                "-label": "T1",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "T2",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250002",
                                "-label": "T3",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "T4",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "T5",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "T6",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250006",
                                "-label": "T7",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250009",
                                "-label": "T8",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250010",
                                "-label": "T9",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250019",
                                "-label": "T10",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250020",
                                "-label": "T11",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250011",
                                "-label": ">T11",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250001",
                        "-label": "Appartement",
                        "ShowOption": [
                            {
                                "-value": "975250012",
                                "-label": "NC",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250000",
                                "-label": "T1",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "T2",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250002",
                                "-label": "T3",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "T4",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "T5",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "T6",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250006",
                                "-label": "T7",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250009",
                                "-label": "T8",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250010",
                                "-label": "T9",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250019",
                                "-label": "T10",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250020",
                                "-label": "T11",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250011",
                                "-label": ">T11",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250011",
                        "-label": "Chambre",
                        "ShowOption": [
                            {
                                "-value": "975250012",
                                "-label": "NC",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250000",
                                "-label": "T1",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250002",
                        "-label": "Aérien",
                        "ShowOption": [
                            {
                                "-value": "975250013",
                                "-label": "Emplacement Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250014",
                                "-label": "Emplacement Simple PMR",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250015",
                                "-label": "Emplacement double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250016",
                                "-label": "Box Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250017",
                                "-label": "Box double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250018",
                                "-label": "Emplacement moto",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250003",
                        "-label": "Sous-sol",
                        "ShowOption": [
                            {
                                "-value": "975250013",
                                "-label": "Emplacement Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250014",
                                "-label": "Emplacement Simple PMR",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250015",
                                "-label": "Emplacement double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250016",
                                "-label": "Box Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250017",
                                "-label": "Box double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250018",
                                "-label": "Emplacement moto",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250005",
                        "-label": "Extérieur",
                        "ShowOption": [
                            {
                                "-value": "975250013",
                                "-label": "Emplacement Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250014",
                                "-label": "Emplacement Simple PMR",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250015",
                                "-label": "Emplacement double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250016",
                                "-label": "Box Simple",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250017",
                                "-label": "Box double",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250018",
                                "-label": "Emplacement moto",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250006",
                        "-label": "Local professionnel",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250007",
                        "-label": "Local commercial",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250008",
                        "-label": "Terrain",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250009",
                        "-label": "Jardin",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250010",
                        "-label": "Cave",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250011",
                        "-label": "Chambre",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250012",
                        "-label": "Cour",
                        "-self-closing": "true"
                    },
                    {
                        "-value": "975250013",
                        "-label": "Autre",
                        "-self-closing": "true"
                    }
                ]
            },
            {
                "-id": "qua_destination",
                "-label": "Destination",
                "DependentField": {
                    "-id": "qua_etat",
                    "-label": "Etat",
                    "-self-closing": "true"
                },
                "Option": [
                    {
                        "-value": "975250000",
                        "-label": "Habitation",
                        "ShowOption": [
                            {
                                "-value": "975250000",
                                "-label": "Cessible",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "861810000",
                                "-label": "Sous préavis",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "861810001",
                                "-label": "Mauvais DPE",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250007",
                                "-label": "Travaux",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250008",
                                "-label": "Dette de loyer",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "Expulsion",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "Squatté",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "Autorisation administrative",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250006",
                                "-label": "Locataire décédé",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250012",
                                "-label": "Vendu hors QT",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "Vente en cours",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250002",
                                "-label": "Vendu",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250002",
                        "-label": "Tertiaire",
                        "ShowOption": [
                            {
                                "-value": "975250000",
                                "-label": "Cessible",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "861810001",
                                "-label": "Mauvais DPE",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250007",
                                "-label": "Travaux",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250008",
                                "-label": "Dette de loyer",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "Expulsion",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "Squatté",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "Autorisation administrative",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250006",
                                "-label": "Locataire décédé",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250012",
                                "-label": "Vendu hors QT",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "Vente en cours",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250002",
                                "-label": "Vendu",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250009",
                                "-label": "À louer ou à céder",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250011",
                                "-label": "Loué",
                                "-self-closing": "true"
                            }
                        ]
                    },
                    {
                        "-value": "975250005",
                        "-label": "Industriel",
                        "ShowOption": [
                            {
                                "-value": "975250000",
                                "-label": "Cessible",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "861810001",
                                "-label": "Mauvais DPE",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250007",
                                "-label": "Travaux",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250008",
                                "-label": "Dette de loyer",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250004",
                                "-label": "Expulsion",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250003",
                                "-label": "Squatté",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250005",
                                "-label": "Autorisation administrative",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250006",
                                "-label": "Locataire décédé",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250012",
                                "-label": "Vendu hors QT",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250001",
                                "-label": "Vente en cours",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250002",
                                "-label": "Vendu",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250009",
                                "-label": "À louer ou à céder",
                                "-self-closing": "true"
                            },
                            {
                                "-value": "975250011",
                                "-label": "Loué",
                                "-self-closing": "true"
                            }
                        ]
                    }
                ]
            }
        ]
    }
};