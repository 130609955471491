import {
    PublicClientApplication,
    InteractionRequiredAuthError,
    Configuration,
    SilentRequest,
} from '@azure/msal-browser';
import { CRM_API, GRAPH_API, CLIENT_ID, TENANT_ID } from './config';


const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: 'https://login.microsoftonline.com/' + TENANT_ID,
        redirectUri: window.location.protocol + '//' + window.location.host,
        navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "localStorage",
    },
};


const msalInstance = new PublicClientApplication(msalConfig);


export const redirectPromise = msalInstance.handleRedirectPromise();


function loginRequired() {
    return !isAuthenticated();
}


export function login() {
    if (!loginRequired()) return true;
    try {
        msalInstance.loginRedirect({ scopes: [CRM_API] });
    } catch (error) {
        console.error('[Login error]', error);
    }
    return false;
}


export function getAccess(scopes, callback) {
    if (!login()) return;
    const request: SilentRequest = {
        scopes,
        account: getAccounts()[0],
    };
    return msalInstance.acquireTokenSilent(request)
        .then(callback)
        .catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                return msalInstance.acquireTokenRedirect(request).then(callback)
                    .catch(function (error) {
                        console.error('[Redirect token error]', error);
                    });
            }
            console.error('[Silent token error]', error);
            throw error;
        });
}


export function getAccounts() {
    return msalInstance.getAllAccounts();
}


export function logout() {
    const accounts = getAccounts();
    if (!accounts.length) return;
    msalInstance.logoutRedirect({ account: accounts[0] });
}


export function isAuthenticated() {
    return getAccounts().length > 0;
}


export function getProfile() {
    return getAccess([GRAPH_API], response => {
        const accessToken = response.accessToken;
        if (!accessToken) return;
        const headers = new Headers({ 'Authorization': `Bearer ${accessToken}` });

        return fetch('https://graph.microsoft.com/v1.0/me', { headers })
            .then(response => response.json())
            .catch(error => {
                console.error('[Graph API error]', error);
                throw error;
            });
    });
}
