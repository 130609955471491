<style lang="scss">
@import "./Base";
</style>

<template>
  <div>
    <transition name="fade">
      <div v-if="!accountError && !account" class="fullpage_info">
        Chargement...
      </div>
    </transition>
    <notifications
      position="bottom left"
      group="scope"
      @click="dismissScopeNotif"
    >
      <template v-slot:body="{ item, close }">
        <div class="vue-notification-template vue-notification">
          <button
            type="button"
            class="btn float-end text-light fs-3 lh-1 py-0"
            @click="close"
          >&times;</button>
          <div v-html="item.text" />
          <div class="text-end">
            <router-link
              class="text-light"
              :to="{ name: 'myaccount' }"
              @click="close"
              >Modifier</router-link
            >
          </div>
        </div>
      </template>
    </notifications>
    <div v-if="!accountError && account">
      <notifications position="bottom right" width="600px" />
      <Topbar />
      <Leftbar :items="menuItems" />
      <div id="main">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
    <transition name="fade">
      <div v-if="accountError" class="fullpage_info">
        {{ accountError }}<br />
        <button class="btn btn-info" @click="logout">Déconnexion</button>
      </div>
    </transition>
  </div>
</template>

<script src="./Base.ts" />
