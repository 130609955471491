import { mapGetters } from "vuex";
import { getAccounts, logout } from "@/utils/msal";
import { DISMISS_SCOPE_NOTIF } from "@/store/mutations";
import Topbar from "@/views/common/Topbar.vue";
import Leftbar from "@/views/common/Leftbar.vue";

export default {
    components: {
        Topbar,
        Leftbar,
    },
    data() {
        return {
            menuItems: [
                {
                    label: "Accueil",
                    icon: "tachometer-alt",
                    route: { name: "accueil" },
                },
                {
                    label: "Plan de vente",
                    icon: "clipboard-list",
                    route: { name: "plan-vente" },
                },
                {
                    label: "Suivi de commercialisation",
                    icon: "hourglass-half",
                    route: { name: "suivi-com" },
                },
                {
                    label: "Suivi des vacants",
                    icon: "sign-out-alt",
                    route: { name: "suivi-vacants" },
                },
                {
                    label: "Suivi des ventes",
                    icon: "credit-card",
                    route: { name: "suivi-ventes" },
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            scopeNotif: "common/scopeNotif",
            account: "common/account",
            accountError: "common/accountError",
            mandantsG: "common/mandants",
            selectedMandantIds: "common/selectedMandantIds",
        }),
        userFullname() {
            const account = getAccounts()[0];
            return account.name;
        },
        mandants() {
            return this.mandantsG
                .filter((mandant) => this.selectedMandantIds.includes(mandant.accountid))
                .map((mandant) => mandant.name)
                .join(", ");
        },
    },
    methods: {
        dismissScopeNotif() {
            this.$store.commit("common/" + DISMISS_SCOPE_NOTIF);
        },
        logout,
    },
    watch: {
        selectedMandantIds(value) {
            this.$notify({ group: "scope", clean: true });
            const text = `Bonjour ${this.userFullname}. Vous consultez les données : ${this.mandants}`;
            this.$notify({ group: "scope", text, duration: -1 });
        },
    },
    mounted() {
        console.log('Logging', getAccounts()[0].username);
        this.$store.dispatch("common/getAccount");
    },
};
