import { fetchAPI, fetchAPISignUp } from '@/utils/fetch';
import { DISMISS_SCOPE_NOTIF, ACCOUNT, SELECT_MANDANTS, INDICATORS, ACCOUNT_ERROR } from './mutations';

const state = {
    scopeNotif: true,
    account: null,
    mandants: [],
    selectedMandantIds: [],
    indicators: [],
    accountError: null,
    csrfToken: null,
};

const getters = {
    scopeNotif: state => state.scopeNotif,
    account: state => state.account,
    mandants: state => state.mandants,
    selectedMandantIds: state => state.selectedMandantIds.length ? state.selectedMandantIds : state.mandants.map(mandant => mandant.accountid),
    indicators: state => state.indicators,
    accountError: state => state.accountError,
    SMIKey: state => state.selectedMandantIds.join(','),
};

const empty404 = (reason) => {
    if (reason.response.status === 404) return [];
    return Promise.reject(reason);
}

function getRealReason(reason) {
    if (!reason.response || !reason.response.data) return reason.message;
    const body = reason.response.data;
    const trans = {
        'Not enabled isportalaccess': 'L\'accès au portail n\'est pas activé pour ce compte',
        'No role set': 'Ce compte n\'a aucun rôle portail',
        'Contact not found by specified email or more than one appeared': 'Votre compte utilisateur ne dispose d\'aucun accès autorisé.\nMerci de contacter le support My Quadral PRO',
    };
    return trans[body] || body;
}

const actions = {
    /** Forces the contact to refresh */
    refreshAccount({ commit }) {
        return fetchAPI('/contact').then(response => {
            let errorMsg;
            if (!response.data) errorMsg = 'Ce compte n\'a pas été trouvé sur le CRM';
            else if (!response.data.linked_accounts?.length) errorMsg = 'Ce compte n\'a aucun rôle portail';
            if (errorMsg) return Promise.reject(new Error(errorMsg));
            commit(ACCOUNT, response.data);
        }).catch((reason) => {
            // commit(ACCOUNT_ERROR, getRealReason(reason));
            commit(ACCOUNT_ERROR, 'Votre compte utilisateur ne dispose pas des autorisations d\'accès au portail.\nMerci de contacter le support My Quadral PRO');
            return Promise.reject(reason);
        });
    },
    /** Gets the contact & mandants if contact is not already there */
    getAccount({ dispatch, commit, state }) {
        // if (state.account) return;
        dispatch('refreshAccount').then(() => {
            commit(SELECT_MANDANTS, state.mandants.map(mandant => mandant.accountid));
        });
    },
    putAccount({ commit }, data) {
        return fetchAPI('/contact', { method: 'put', data });
    },
    getAccountPre({ state }) {
        return fetchAPISignUp('/contact/pre').then(response => {
            console.log(response.headers);
            state.csrfToken = response.headers['xsrf-token'];
        });
    },
    postAccount({ state }, data) {
        if (!state.csrfToken) return Promise.reject('No token');
        return fetchAPISignUp('/contact', { method: 'post', data, headers: { 'X-CSRF-TOKEN': state.csrfToken }/*, withCredentials: true*/ });
    },
    getIndicators({ commit }) {
        return fetchAPI('/indicators').then(response => {
            commit(INDICATORS, response.data);
        });
    },
    search({ commit }, search) {
        return Promise.all([
            fetchAPI('/bienimmo/search/' + search).then(response => response.data).catch(empty404),
            fetchAPI('/program/search/' + search).then(response => response.data).catch(empty404),
        ]).then(([bienimmos, programs]) => ({ bienimmos, programs }));
    },
    searchCommunes({ commit }, search) {
        return fetchAPI('/communes/search/' + search).then(response => response.data).catch(empty404);
    },
}

const mutations = {
    [DISMISS_SCOPE_NOTIF](state) {
        state.scopeNotif = false;
    },
    [ACCOUNT](state, data) {
        state.account = data;
        state.mandants = data.linked_accounts;
    },
    [SELECT_MANDANTS](state, mandantIds) {
        state.selectedMandantIds = mandantIds;
    },
    [INDICATORS](state, data) {
        state.indicators = data;
    },
    [ACCOUNT_ERROR](state, error) {
        state.accountError = error;
    },
};

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
};
