<template>
  <div
    class="
      position-absolute
      top-50
      start-50
      translate-middle
      text-muted
      fw-light
      fs-2
    "
  >
    Désolé cette page n'existe pas
  </div>
</template>
