import { setModalOpen } from "./display";
import { isAuthenticated, redirectPromise } from "./msal";


function setPageTitle(to: any) {
    const appName = 'My Quadral PRO';
    const nearestWithTitle = to.matched.slice().reverse().find((r: any) => r.meta && r.meta.title);
    document.title = appName + (nearestWithTitle ? ' - ' + nearestWithTitle.meta.title : '');
}


function setBodyId(to: any) {
    document.body.id = 'route_' + to.name;
}


function globalGuard(to, from, next) {
    // console.log(window.location.href, 'authenticated ?', isAuthenticated());
    redirectPromise.then(tokenResponse => {
        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect. 
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        if (!to.matched[0].meta.public && !isAuthenticated()) return next({ name: 'login' });
        setModalOpen(false);
        setPageTitle(to);
        setBodyId(to);
        next();
    }).catch((error) => {
        console.error(error);
        // handle error, either in the library or coming back from the server
    });
}


export {
    globalGuard,
}
