<style lang="scss">
@import "./LoginAccessRequest";
</style>

<template>
  <Modal ref="modal">
    <template #headerTitle>Demande d'accès</template>
    <form
      :class="['row', 'g-3']"
      id="access_request_form"
      @submit.prevent="submit"
    >
      <div class="col-6">
        <label for="firstname" class="form-label required">Prénom</label>
        <input
          id="firstname"
          type="firstname"
          :class="['form-control', { 'is-invalid': !validAccount.firstname }]"
          v-model="account.firstname"
          required
        />
      </div>
      <div class="col-6">
        <label for="lastname" class="form-label required">Nom</label>
        <input
          id="lastname"
          type="lastname"
          :class="['form-control', { 'is-invalid': !validAccount.lastname }]"
          v-model="account.lastname"
          required
        />
      </div>
      <div>
        <label for="email1" class="form-label required"
          >Ne pas remplir ce champ *</label
        >
        <input
          id="email1"
          type="text"
          class="form-control required"
          v-model="email1"
        />
      </div>
      <div>
        <label for="email" class="form-label required">Email</label>
        <input
          id="email"
          type="email"
          :class="[
            'form-control',
            { 'is-invalid': !validAccount.emailaddress1 },
          ]"
          v-model="account.emailaddress1"
          required
        />
      </div>
      <div>
        <label for="mso_companyacessrequest" class="form-label required"
          >Société</label
        >
        <input
          id="mso_companyacessrequest"
          type="text"
          :class="[
            'form-control',
            { 'is-invalid': !validAccount.mso_companyacessrequest },
          ]"
          v-model="account.mso_companyacessrequest"
          required
        />
      </div>
    </form>
    <template #footer>
      <div class="spinner-border text-info me-auto" v-if="loading">
        <span class="visually-hidden">Chargement...</span>
      </div>
      <button type="button" class="btn btn-secondary" @click="close">
        Annuler
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        form="access_request_form"
        :disabled="loading || leash"
      >
        Demander l'accès
      </button>
    </template>
  </Modal>
</template>

<script src="./LoginAccessRequest.ts" />
