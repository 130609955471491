<style lang="scss">
.mini_bi {
    white-space: nowrap;
    span:not(:last-of-type) {
        margin-right: 15px;
    }
}
</style>

<template>
  <span class="mini_bi">
    <span title="Typologie" v-if="bienimmo.qua_typologie_label">
      <fa icon="border-style" class="fw-light" />
      {{ bienimmo.qua_typologie_label }}
    </span>
    <span title="Étage" v-if="bienimmo.qua_etage_label">
      <fa icon="layer-group" class="fw-light" />
      {{ bienimmo.qua_etage_label }}
    </span>
    <span title="Surface habitable" v-if="bienimmo.qua_surfacehabitable_label">
      <fa icon="ruler-vertical" class="fw-light" />
      {{ bienimmo.qua_surfacehabitable_label }} &#13217;
    </span>
  </span>
</template>

<script>

export default {
    props: ['bienimmo'],
}
</script>
