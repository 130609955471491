import {
    biListType,
    link,
    optionSet
} from "./interfaces";

export function convertCrmResponse(response) {
    response.data = response.value;
    response.isMoreRecords = response[MORE_RECORDS];
    response.totalCount = response[TOTAL_RECORDCOUNT];
    return response;
}
export function convertApiParams(params) {
    params.pageNumber = params.page || 1;
    delete params.page;
    params.pageSize = 10;
}

/* Dynamics keys */

const CRM_ANNOTATION = '@Microsoft.Dynamics.CRM';
export const ANNOTATION_PREFIX = '@OData.Community.Display.V1';
export const ANNOTATION_FORMAT = 'FormattedValue';
export const PAGING_COOKIE = CRM_ANNOTATION + '.fetchxmlpagingcookie';
export const MORE_RECORDS = CRM_ANNOTATION + '.morerecords';
export const TOTAL_RECORDCOUNT = CRM_ANNOTATION + '.totalrecordcount';

export const USER = 'systemuser';
export const ACCOUNT = 'account';

export const STATUT_ACTIF = 975250000;

/* Contact roles */

export const CT_ROLE_READER = 861810001;
export const CT_ROLE_MASTER = 861810000;

/* Program */

export const PV_ETAT_ETUDEPRECOM = 1;
export const PV_ETAT_PRECOM = 975250000;
export const PV_ETAT_COMMER = 975250001;
export const PV_ETAT_CLOTUR = 975250002;
export const PV_ETAT_PREPAR = 975250003;
export const PV_ETAT_VENDU = 975250004;
export const PV_ETAT_STOPPE = 975250005;

export const PV_ETAT:optionSet[] = [
    {
        id: PV_ETAT_ETUDEPRECOM,
        label: 'Etude de pré-commercialisation',
    },
    {
        id: PV_ETAT_PRECOM,
        label: 'Pré-commercialisation (neuf)',
    },
    {
        id: PV_ETAT_PREPAR,
        label: 'En préparation',
    },
    {
        id: PV_ETAT_COMMER,
        label: 'Commercialisation',
    },
    {
        id: PV_ETAT_CLOTUR,
        label: 'Clôturé',
    },
    {
        id: PV_ETAT_STOPPE,
        label: 'Stoppé',
    },
    {
        id: PV_ETAT_VENDU,
        label: 'Vendu',
    },
];

export const PV_ETATS_PROGRESS = [
    {
        id: PV_ETAT_ETUDEPRECOM,
        show: 'Étude de pré-com.',
    },
    {
        id: PV_ETAT_PREPAR,
    },
    {
        id: PV_ETAT_COMMER,
        overrides: [PV_ETAT_PRECOM, PV_ETAT_STOPPE],
    },
    {
        id: PV_ETAT_CLOTUR,
        overrides: [PV_ETAT_VENDU],
    },
];

export const INTERLO_MAPPING = {
    [USER]: {
        name: 'fullname',
        phone: 'mobilephone',
        email: 'internalemailaddress',
    },
    [ACCOUNT]: {
        name: 'name',
        phone: 'telephone1',
        email: 'emailaddress1',
    },
};

export const NOTAIRE_MANDANT_INTERLO =
    { id: 'qua_notairemandantid', type: ACCOUNT, label: 'Notaire mandant' };

export const INTERLOS = [
    { id: 'qua_gestionnaireadvid', type: USER, label: 'Gestionnaire ADV' },
    { id: 'qua_commercialreferentid', type: USER, label: 'Commercial référent du PV' },
    { id: 'qua_chargemarketingid', type: USER, label: 'Chargé Marketing' },
    { id: 'qua_chargefinancementid', type: USER, label: 'Chargé de financement' },
    { id: 'qua_chargeadvid', type: USER, label: 'Chargé ADV' },
    NOTAIRE_MANDANT_INTERLO,
];

export function getInterLinks() {
    return INTERLOS.map(interlo => {
        const link: link = {
            $: { name: interlo.type, to: interlo.id, alias: interlo.id, 'link-type': 'outer' },
            attribute: Object.values(INTERLO_MAPPING[interlo.type]).map((name: string) => ({ $: { name } }))
        };
        return link;
    });
}

/* Bien Immobiliers */

export const BI_DESTIN_HABITATION = 975250000;

export const BI_DESTIN:optionSet[] = [
    { id: 975250004, label: 'Divers' },
    { id: 975250003, label: 'Foncier' },
    { id: BI_DESTIN_HABITATION, label: 'Habitation' },
    { id: 975250005, label: 'Industriel' },
    { id: 975250001, label: 'Stationnement' },
    { id: 975250002, label: 'Tertiaire' },
];
export const BI_ETAGE:optionSet[] = [
    { id: 974250000, label: '-4' },
    { id: 974250001, label: '-3' },
    { id: 974250003, label: '-2' },
    { id: 974250004, label: '-1' },
    { id: 974250005, label: 'Rez-de-Jardin' },
    { id: 975250041, label: 'Dernier étage' },
    { id: 975250042, label: 'Entresol' },
    { id: 975250043, label: 'Rez de dalle' },
    { id: 975250000, label: '0' },
    { id: 975250001, label: '0,5' },
    { id: 975250002, label: '1' },
    { id: 975250003, label: '1,5' },
    { id: 975250004, label: '2' },
    { id: 975250005, label: '2,5' },
    { id: 975250006, label: '3' },
    { id: 975250007, label: '3,5' },
    { id: 975250008, label: '4' },
    { id: 975250009, label: '4,5' },
    { id: 975250010, label: '5' },
    { id: 975250011, label: '5,5' },
    { id: 975250012, label: '6' },
    { id: 975250013, label: '6,5' },
    { id: 975250014, label: '7' },
    { id: 975250015, label: '7,5' },
    { id: 975250016, label: '8' },
    { id: 975250017, label: '8,5' },
    { id: 975250018, label: '9' },
    { id: 975250019, label: '9,5' },
    { id: 975250020, label: '10' },
    { id: 975250021, label: '10,5' },
    { id: 975250022, label: '11' },
    { id: 975250023, label: '11,5' },
    { id: 975250024, label: '12' },
    { id: 975250025, label: '12,5' },
    { id: 975250026, label: '13' },
    { id: 975250027, label: '13,5' },
    { id: 975250028, label: '14' },
    { id: 975250029, label: '14,5' },
    { id: 975250030, label: '15' },
    { id: 975250036, label: '16' },
    { id: 975250037, label: '17' },
    { id: 975250038, label: '18' },
    { id: 975250039, label: '19' },
    { id: 975250040, label: '20' }
];
export const BI_NATURE:optionSet[] = [
    { id: 975250017, label: 'Bureau' },
    { id: 975250014, label: 'Usine' },
    { id: 975250015, label: 'Hangar' },
    { id: 975250000, label: 'Maison' },
    { id: 975250001, label: 'Appartement' },
    { id: 975250002, label: 'Aérien' },
    { id: 975250003, label: 'Sous-sol' },
    { id: 975250005, label: 'Cellier' },
    { id: 975250016, label: 'Séchoir' },
    { id: 975250004, label: 'Extérieur' },
    { id: 975250006, label: 'Local professionnel' },
    { id: 975250007, label: 'Local commercial' },
    { id: 975250008, label: 'Terrain' },
    { id: 975250009, label: 'Jardin' },
    { id: 975250010, label: 'Cave' },
    { id: 975250011, label: 'Chambre' },
    { id: 975250012, label: 'Cour' },
    { id: 975250013, label: 'Autre' }
];
export const BI_TYPOLOGIE:optionSet[] = [
    { id: 975250013, label: 'Emplacement Simple' },
    { id: 975250014, label: 'Emplacement Simple PMR' },
    { id: 975250015, label: 'Emplacement double' },
    { id: 975250016, label: 'Box Simple' },
    { id: 975250017, label: 'Box double' },
    { id: 975250018, label: 'Emplacement moto' },
    { id: 975250000, label: 'T1' },
    { id: 975250001, label: 'T2' },
    { id: 975250002, label: 'T3' },
    { id: 975250003, label: 'T4' },
    { id: 975250004, label: 'T5' },
    { id: 975250005, label: 'T6' },
    { id: 975250006, label: 'T7' },
    { id: 975250009, label: 'T8' },
    { id: 975250010, label: 'T9' },
    { id: 975250019, label: 'T10' },
    { id: 975250020, label: 'T11' },
    { id: 975250011, label: '>T11' },
    { id: 975250012, label: 'NC' },
    { id: 975250007, label: 'Simple' },
    { id: 975250008, label: 'Double' }
];
export const BI_ETAT_CESSIBLE = 975250000;
export const BI_ETAT_VENTEENC = 975250001;
export const BI_ETAT_VENDU = 975250002;
export const BI_ETAT_VENDUHQT = 975250012;
const BI_ETAT_CESSIBLE_OPTION:optionSet = { id: BI_ETAT_CESSIBLE, label: 'Cessible' };
const BI_ETAT_VENDUHQT_OPTION:optionSet = { id: BI_ETAT_VENDUHQT, label: 'Vendu hors QT' };
const BI_ETAT_VENTEENC_OPTION:optionSet = { id: BI_ETAT_VENTEENC, label: 'Vente en cours' };
const BI_ETAT_VENDU_OPTION:optionSet = { id: BI_ETAT_VENDU, label: 'Vendu' };
export const BI_ETAT:optionSet[] = [
    BI_ETAT_CESSIBLE_OPTION,
    { id: 861810000, label: 'Sous préavis' },
    { id: 975250007, label: 'Travaux' },
    { id: 975250008, label: 'Dette de loyer' },
    { id: 975250004, label: 'Expulsion' },
    { id: 975250003, label: 'Squatté' },
    { id: 975250005, label: 'Autorisation administrative' },
    { id: 975250006, label: 'Locataire décédé' },
    BI_ETAT_VENDUHQT_OPTION,
    BI_ETAT_VENTEENC_OPTION,
    BI_ETAT_VENDU_OPTION,
    { id: 100000001, label: 'Élément ' },
    { id: 975250009, label: 'À louer ou à céder' },
    { id: 975250010, label: 'À louer' },
    { id: 975250011, label: 'Loué' },
    { id: 861810001, label: 'Mauvais DPE' }
];
export const BI_OCCUPE = 1;
export const BI_VACANT = 0;
export const BI_OCCVAC = [
    { id: BI_OCCUPE, label: 'Occupé' },
    { id: BI_VACANT, label: 'Vacant' },
];

const EXCLUDE_CESSIBLE = [-BI_ETAT_VENDUHQT, -BI_ETAT_VENDU, -BI_ETAT_VENTEENC, -BI_ETAT_CESSIBLE];
const EXCLUDE_VENTE    = [-BI_ETAT_VENDUHQT, -BI_ETAT_VENDU, -BI_ETAT_VENTEENC];
const EXCLUDE_CESSIBLE_OPTION = { id: EXCLUDE_CESSIBLE, label: 'Non cessible' };

export const BI_ETAT_BY_LIST_TYPE = {
    [biListType.program]: {
        etat: {
            default: [],
            toggles: [
                BI_ETAT_VENTEENC_OPTION,
                BI_ETAT_VENDU_OPTION,
                BI_ETAT_CESSIBLE_OPTION,
                EXCLUDE_CESSIBLE_OPTION,
            ],
        },
        occvac: {
            default: null,
        },
    },
    [biListType.com]: {
        etat: {
            default: EXCLUDE_VENTE,
            toggles: [
                BI_ETAT_CESSIBLE_OPTION,
                EXCLUDE_CESSIBLE_OPTION,
            ],
        },
        occvac: {
            default: BI_OCCUPE,
        },
    },
    [biListType.vacants]: {
        etat: {
            default: EXCLUDE_VENTE,
            toggles: [
                BI_ETAT_CESSIBLE_OPTION,
                EXCLUDE_CESSIBLE_OPTION,
            ],
        },
        occvac: {
            default: BI_VACANT,
        },
    },
    [biListType.ventes]: {
        etat: {
            default: [BI_ETAT_VENDU, BI_ETAT_VENTEENC],
            toggles: [
                BI_ETAT_VENDU_OPTION,
                BI_ETAT_VENTEENC_OPTION,
            ],
        },
        occvac: {
            default: null,
        },
    },
};

export const BI_ETATS_SHORT = [
    { label: 'En cours de commercialisation' },
    { label: 'Vente en cours' },
    { label: 'Vendu' },
];

/* Locataire principal */

export const LP_CIVILITE:optionSet[] = [
    { id: 975250000, label: 'Monsieur' },
    { id: 975250001, label: 'Madame' },
    { id: 100000000, label: 'Monsieur et Madame' },
    { id: 975250002, label: 'Monsieur le directeur' },
    { id: 975250003, label: 'Monsieur le président' },
    { id: 975250004, label: 'Madame la présidente' },
    { id: 975250005, label: 'Maître' },
    { id: 975250006, label: 'Madame la directrice' }
];

export const LP_SITFAM:optionSet[] = [
    { id: 975250000, label: 'Célibataire' },
    { id: 975250001, label: 'Marié(e)' },
    { id: 975250002, label: 'Pacsé(e)' },
    { id: 975250003, label: 'Divorcé(e)' },
    { id: 975250004, label: 'Veuf(ve)' }
];

export const LP_SCORING:optionSet[] = [
    { id: 975250000, label: 'N\'achètera jamais', icon: 'frown' },
    { id: 975250001, label: 'Faible chance d\'achat', icon: 'meh' },
    { id: 975250002, label: 'Forte chance d\'achat', icon: 'meh' },
    { id: 975250003, label: 'Achat assuré', icon: 'smile' },
];
