import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAt,
    faArrowLeft,
    faArrowRight,
    faBorderStyle,
    faBuilding,
    faCalendar,
    faChevronRight,
    faClipboardList,
    faCreditCard,
    faCrown,
    faDatabase,
    faEdit,
    // faEuroSign,
    // faFlagCheckered,
    faFrown,
    faLayerGroup,
    faHome,
    faHourglassHalf,
    faImage,
    faMapMarker,
    faMeh,
    faMobile,
    faPhone,
    faRulerVertical,
    faSignOutAlt,
    faSmile,
    faSortAmountDown,
    faSortAmountUp,
    faTachometerAlt,
    faBan,
    faUnlock,
    faUser,
    faUserCog,
    faWalking,
} from '@fortawesome/free-solid-svg-icons'

library.add({
    faAt,
    faArrowLeft,
    faArrowRight,
    faBorderStyle,
    faBuilding,
    faCalendar,
    faChevronRight,
    faClipboardList,
    faCreditCard,
    faCrown,
    faDatabase,
    faEdit,
    // faEuroSign,
    // faFlagCheckered,
    faFrown,
    faLayerGroup,
    faHome,
    faHourglassHalf,
    faImage,
    faMapMarker,
    faMeh,
    faMobile,
    faPhone,
    faRulerVertical,
    faSignOutAlt,
    faSmile,
    faSortAmountDown,
    faSortAmountUp,
    faTachometerAlt,
    faBan,
    faUnlock,
    faUser,
    faUserCog,
    faWalking,
})
