import {
    mapCrmToApi,
    PROGRAM_API_MAPPING
} from "@/utils/crm/crm";
import { fetchAPI } from "@/utils/fetch";
import { convertApiParams } from "@/utils/crm/crm-constants";


const getDefaultState = () => {
    return {
        programs: [],
    };
};
const state = getDefaultState();


const getters = {
    programs: state => state.programs,
};


function mapPrograms(data) {
    data.data.forEach((value) => mapCrmToApi(value, PROGRAM_API_MAPPING));
    return data;
}

const actions = {
    getList({ rootGetters }, params) {
        params.accounts = rootGetters['common/selectedMandantIds'];
        convertApiParams(params);
        return fetchAPI('/programs', { method: 'POST', data: params })
            .then(response => response.data)
            .then(mapPrograms);
    },
    getById({ commit }, id) {
        return fetchAPI('/program/' + id)
            // .then(mapPrograms) // Not needed here, fields are already well suffixed (_label)
            .then(response => response.data);
    },
    getInterlos({ commit }, id) {
        return fetchAPI('/program/' + id + '/interlos').then(response => response.data);
    },
}


const mutations = {
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
