import { createStore } from 'vuex'
import loading from './loading'
import common from './common'
import program from './program'
import bienimmo from './bienimmo'

export default createStore({
  modules: {
    loading,
    common,
    program,
    bienimmo,
  }
})
