<template>
  <div>
    <div class="modal" tabindex="-1" @click="close" @keyup.esc="close" ref="modal">
      <transition name="fade" @after-leave="$emit('close')">
        <div class="modal-dialog" @click.stop v-if="showContent">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-info"><slot name="headerTitle"></slot></h5>
              <button type="button" class="btn-close" @click="close"></button>
            </div>
            <div class="modal-body">
              <slot></slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div class="modal-backdrop" v-if="showContent"></div>
    </transition>
  </div>
</template>

<script>
import { setModalOpen } from "@/utils/display.ts";

export default {
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    setBodyStyle() {
      setModalOpen(this.showContent);
    },
    close() {
      this.showContent = false;
    },
  },
  mounted() {
    this.$refs.modal.focus();
    this.showContent = true;
    this.setBodyStyle();
  },
  unmounted() {
    this.setBodyStyle();
  },
};
</script>
