export interface condition {
    $: {
        attribute: string,
        operator: string,
        value?: string | number,
    }
    value?: string[] | number[],
}

export interface filter {
    $: {
        type: string,
    },
    condition: condition[],
    filter?: filter,
}

export interface attribute {
    $: {
        name: string,
    }
}

export interface link {
    $: {
        name: string,
        from?: string,
        to?: string,
        'link-type'?: string,
        alias?: string,
    },
    attribute: attribute[],
    filter?: filter,
}

export interface optionSet {
    id: number,
    label: string,
    icon?: string,
}

export interface apiMappingPrefix {
    crm: string,
    api: string,
}

export interface apiMapping {
    crm?: string,
    api: string,
    format?: boolean,
    prefix?: apiMappingPrefix,
    lookup?: boolean,
}

export interface pagination {
    page?: number,
    count?: number,
    pagingcookie?: string,
    returntotalrecordcount?: boolean,
}

export enum biListType {
    program = 'program',
    com = 'com',
    vacants = 'vacants',
    ventes = 'ventes',
}

export interface bienimmosParams {
    page: number,
    type: biListType,
    programId?: string,
    search?: string,
}
