import { mapGetters } from "vuex";
import Modal from "@/views/common/Modal.vue";

function getValidAccount() {
    return {
        emailaddress1: true,
        mso_companyacessrequest: true,
        firstname: true,
        lastname: true,
    };
}

export default {
    components: { Modal },
    data() {
        return {
            account: {
                emailaddress1: null,
                mso_companyacessrequest: null,
                firstname: null,
                lastname: null,
            },
            validAccount: getValidAccount(),
            email1: null,
            leash: true,
        };
    },
    computed: {
        ...mapGetters({
            loading: "loading/loading",
        }),
    },
    methods: {
        close() {
            this.$refs.modal.close();
        },
        placeLeash() {
            this.leash = true;
            const vm = this;
            setTimeout(() => {
                vm.leash = false;
            }, 4000);
        },
        isValid() {
            if (this.leash) return false;
            if (this.email1) return false;
            Object.keys(this.validAccount).forEach((key) => {
                this.validAccount[key] = !!this.account[key];
            });
            const someInvalidFields = Object.values(this.validAccount).some(
                (value) => !value
            );
            if (someInvalidFields) return false;
            return true;
        },
        submit() {
            if (!this.isValid()) return;
            this.$store
                .dispatch("common/postAccount", this.account)
                .then(() => {
                    this.$notify({
                        type: "success",
                        text: "Votre demande a bien été prise en compte",
                    });
                    this.close();
                })
                .catch((reason) => {
                    this.placeLeash();
                    this.validAccount = getValidAccount();
                });
        },
    },
    mounted() {
        this.placeLeash();
        this.$store.dispatch('common/getAccountPre');
    },
};
