<style lang="scss">
@import "./Login";
</style>

<template>
  <div class="login_container">
    <div class="title">
      <div class="title_1">Pilotez vos objectifs</div>
      <span class="title_2"
        >Maîtrisez chaque étape<br class="d-none d-xxl-inline" />
        du cycle de vente</span
      >
    </div>
    <div class="login_panel shadow" ref="container">
      <div class="welcome">
        Bienvenue sur
        <div class="fw-bold">My Quadral <span class="text-info">PRO</span></div>
      </div>
      <div class="login_center">
        <span class="login_button" @click="login">
          <div class="start">
            <img src="@/assets/img/microsoft-logo.svg" />
          </div>
          <div class="text">Connexion</div>
          <div class="end">
            <fa icon="arrow-right" />
          </div>
        </span>
        <div class="mt-4">
          <div class="text-center">
            <span class="fw-light me-1">Difficulté de connexion ?</span>
            <a href="mailto:support.mqpro@quadral.fr">Contactez-nous</a>
          </div>
          <div class="text-center">
            <button class="btn btn-link" @click="accessRequest = true">
              Demander un accès
            </button>
          </div>
        </div>
      </div>
    </div>
    <LoginAccessRequest v-if="accessRequest" @close="accessRequest = false" />
    <notifications position="bottom right" width="600px" />
  </div>
</template>

<script>
import { login } from "@/utils/msal";
import LoginAccessRequest from "./LoginAccessRequest.vue";

export default {
  components: { LoginAccessRequest },
  data() {
    return {
      accessRequest: false,
      production: process.env.NODE_ENV === 'production',
    };
  },
  methods: {
    login() {
      this.$refs.container.style.width = "100%";
      if (login()) {
        this.$router.push({ name: "accueil" });
      }
    },
  },
};
</script>
