<template>
  <nav
    class="navbar topbar right0 shadow navbar-expand-lg navbar-light bg-light"
  >
    <div class="container-fluid">
      <router-link :to="{ name: 'accueil' }" class="navbar-brand fw-bold">
        My Quadral <span class="text-info">PRO</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        @click="topbarOpen = !topbarOpen"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div :class="['collapse', 'navbar-collapse', { show: topbarOpen }]">
        <ul class="navbar-nav mb-2 d-lg-none">
          <li class="nav-item">
            <router-link
              :to="{ name: 'myaccount' }"
              class="nav-link"
              @click="topbarOpen = false"
            >
              <fa icon="user" /> Mon compte
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logout">
              <fa icon="unlock" /> Déconnexion
            </a>
          </li>
        </ul>
        <div class="ms-auto d-flex align-items-center">
          <Search />
          <div class="position-relative d-none d-lg-block">
            <button
              class="btn btn-primary ms-2"
              @click="
                formsOpen = false;
                paramsOpen = !paramsOpen;
              "
            >
              <fa icon="user-cog" />
            </button>
            <ul
              :class="['dropdown-menu', 'end-0', { show: paramsOpen }]"
              @mouseover="paramsOpen = true"
              @mouseout="paramsOpen = false"
            >
              <li>
                <router-link :to="{ name: 'myaccount' }" class="dropdown-item" @click="paramsOpen = false">
                  <fa icon="user" /> Mon compte
                </router-link>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#" @click="logout"
                  ><fa icon="unlock" /> Déconnexion</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="loading_line"></div>
  </nav>
</template>

<script>
import { logout } from "@/utils/msal";
import Search from "@/views/common/Search";

export default {
  components: { Search },
  data() {
    return {
      topbarOpen: false,
      formsOpen: false,
      paramsOpen: false,
    };
  },
  methods: {
    logout,
  },
};
</script>
