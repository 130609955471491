import {
    BIENIMMO_API_MAPPING,
    mapCrmToApi,
} from "@/utils/crm/crm";
import { fetchAPI } from "@/utils/fetch";
import { convertApiParams } from "@/utils/crm/crm-constants";


const getDefaultState = () => {
    return {
        bienimmos: [],
    };
};
const state = getDefaultState();


const getters = {
    bienimmos: state => state.bienimmos,
};


function mapBienimmos(response) {
    response.data.forEach((value) => mapCrmToApi(value, BIENIMMO_API_MAPPING));
    return response;
}


const actions = {
    getList({ rootGetters }, params) {
        params.accounts = rootGetters['common/selectedMandantIds'];
        convertApiParams(params);
        return fetchAPI('/bienimmos', { method: 'POST', data: params })
            .then(response => response.data)
            .then(mapBienimmos);
    },
    getById({ commit }, id) {
        return fetchAPI('/bienimmo/' + id)
            // .then(mapBienimmos) // Not needed here, fields are already well suffixed (_label)
            .then(response => response.data);
    },
    getLocaprinc({ commit }, id) {
        return fetchAPI('/bienimmo/' + id + '/locaprinc').then(response => response.data);
    },
    getEntretiens({ commit }, id) {
        return fetchAPI('/bienimmo/' + id + '/entretien').then(response => response.data);
    },
    getDossiers({ commit }, id) {
        const empty404 = (reason) => {
            if (reason.response.status === 404) return [];
            return Promise.reject(reason);
        }
        return fetchAPI('/bienimmo/' + id + '/dossier').then(response => response.data).catch(empty404);
    },
    put({ commit }, { id, data }) {
        return fetchAPI('/bienimmo/' + id, { method: 'PUT', data });
    },
    putVacant({ commit }, { id, data }) {
        return fetchAPI('/bienimmo/' + id + '/vacant', { method: 'PUT', data });
    },
    putLocaprinc({ commit }, { id, data }) {
        return fetchAPI('/bienimmo/' + id + '/locaprinc', { method: 'PUT', data });
    },
}


const mutations = {
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
