import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';
import { globalGuard } from './utils/guards';
import Login from './views/common/Login.vue';
import Base from './views/common/Base.vue';
import CGU from './views/common/CGU.vue';
import NotFound from './views/common/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Connexion', public: true },
    },
    {
        path: '/',
        component: Base,
        children: [
            {
                path: '',
                name: 'accueil',
                component: () => import(/* webpackChunkName: "accueil" */ './views/accueil/Accueil.vue'),
            },
            {
                path: '/mentions-legales',
                name: 'cgu',
                component: CGU,
                meta: { title: 'Mentions Légales - CGU' },
            },
            {
                path: '/mon-compte',
                name: 'myaccount',
                component: () => import(/* webpackChunkName: "accueil" */ './views/common/MyAccount.vue'),
                meta: { title: 'Mon compte' },
            },
            {
                path: '/plan-de-vente',
                name: 'plan-vente',
                component: () => import(/* webpackChunkName: "plan-vente" */ './views/plan-vente/PlanVente.vue'),
                meta: { title: 'Plan de vente' },
            },
            {
                path: '/programme-de-vente/:id',
                name: 'program',
                component: () => import(/* webpackChunkName: "program" */ './views/program/Program.vue'),
                meta: { title: 'Programme de vente' },
                props: true,
            },
            {
                path: '/suivi-commercialisation',
                name: 'suivi-com',
                component: () => import(/* webpackChunkName: "suivi-com" */ './views/suivi-com/SuiviCom.vue'),
                meta: { title: 'Suivi de commercialisation' },
            },
            {
                path: '/suivi-vacants',
                name: 'suivi-vacants',
                component: () => import(/* webpackChunkName: "suivi-vacants" */ './views/suivi-vacants/SuiviVacants.vue'),
                meta: { title: 'Suivi des vacants' },
            },
            {
                path: '/suivi-ventes',
                name: 'suivi-ventes',
                component: () => import(/* webpackChunkName: "suivi-ventes" */ './views/suivi-ventes/SuiviVentes.vue'),
                meta: { title: 'Suivi des ventes' },
            },
            {
                path: '/bien-immo/:id',
                name: 'bien-immo',
                component: () => import(/* webpackChunkName: "bien-immo" */ './views/bien-immo/BienImmo.vue'),
                meta: { title: 'Bien immobilier' },
                props: true,
            },
        ]
    },
    {
        path: '/:path(.*)*',
        name: 'notfound',
        component: NotFound,
        meta: { title: 'Page inexistante', public: true },
    },
];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 };
    },
    history: createWebHistory(),
    routes,
});

router.beforeEach(globalGuard);

export default router;
