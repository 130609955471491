export let mockingEnabled = false

interface Mock {
    url: string | RegExp,
    response: object,
    method: string,
}

const mocks: Mock[] = []

export function addMock(url, response, method = null) {
    mocks.push({ url, response, method })
}

export function enableMocking(state) {
    mockingEnabled = state
}

function findMock(config) {
    return mocks.find((mock) => {
        if (config.method && mock.method && config.method.toLowerCase() !== mock.method.toLowerCase()) return false;
        return (typeof mock.url === 'string') ? mock.url === config.url : mock.url.test(config.url);
    })
}

export function isUrlMocked(config) {
    return !!findMock(config);
}

export const getMockError = config => {
    const mockError: any = new Error()
    mockError.mockData = findMock(config).response
    mockError.config = config
    return Promise.reject(mockError)
}

export const isMockError = error => Boolean(error.mockData)

export const getMockResponse = mockError => {
    const { mockData, config } = mockError
    // Handle mocked error (any non-2xx status code)
    if (mockData.status && String(mockData.status)[0] !== '2') {
        const err: any = new Error(mockData.message || 'mock error')
        err.code = mockData.status
        return Promise.reject(err)
    }
    // Handle mocked success
    return Promise.resolve(Object.assign({
        data: {},
        status: 200,
        statusText: 'OK',
        headers: {},
        config,
        isMock: true
    }, mockData))
}
