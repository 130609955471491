export function moneyFormat(num) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num);
}
export function numberFormat(num) {
    return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(num);
}


export function nFormatArray(num, digits = 1) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return [
        item ? (num / item.value).toFixed(digits).replace(rx, "$1") : "0",
        item ? item.symbol : "",
    ];
}


export function nFormat(num, digits = 1) {
    const item = nFormatArray(num, digits);
    return item[0] + item[1];
}


function getScrollbarWidth() {
    const outer:any = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll";
    outer.style.msOverflowStyle = "scrollbar";
    document.body.appendChild(outer);
    const inner = document.createElement("div");
    outer.appendChild(inner);
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
}

export function setModalOpen(show) {
    const $body = document.getElementsByTagName("body")[0];
    const scrollWidth = getScrollbarWidth() + "px";
    $body.style.marginRight = show ? scrollWidth : null;
    $body.style.overflow = show ? "hidden" : null;
    const $ftops:any = document.getElementsByClassName("right0");
    for (let i = 0, max = $ftops.length; i < max; i++) {
        $ftops[i].style.right = show ? scrollWidth : 0;
    }
}

export function cap(number, { min = null, max = null }) {
    number = min ? Math.max(number, min) : number;
    number = max ? Math.min(number, max) : number;
    return number;
}
