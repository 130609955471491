export const API_HOST = process.env.VUE_APP_API_HOST;
// export const API_HOST = 'https://qbox-api-preprod.azurewebsites.net';
export const CRM_HOST = process.env.VUE_APP_CRM_HOST;
// export const CRM_HOST = 'https://qtboxpreprod.crm4.dynamics.com';
export const CRM_ENTRYPOINT = '/api/data/v9.1';
export const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
export const TENANT_ID = process.env.VUE_APP_TENANT_ID;
export const GRAPH_API = 'https://graph.microsoft.com/User.Read';
export const CRM_API = `${CRM_HOST}/user_impersonation`;
export const DATE_FORMAT = 'DD/MM/YYYY';
